/* eslint sort-keys: error */
var storage = window.storage || {};
var saveStorage = function (key, value) {
    storage[key] = value;
    postMessage({ action: 'saveStorage', storage: storage });
};
var postMessage = function (message) {
    var post = function (json) {
        return window.ReactNativeWebView && window.ReactNativeWebView.postMessage
            ? window.ReactNativeWebView.postMessage(json)
            : window.postMessage(json);
    };
    post(JSON.stringify(message));
};
export default {
    apiBase: window.apiBase,
    appVersion: '1.0.1', // APP_VERSION
    clearStorage: function () { },
    clearUser: function () { return postMessage({ action: 'signOut' }); },
    getPage: null,
    loadExportCharset: function () { },
    loadExportDataType: function () { },
    loadExportTimeFormat: function () { },
    loadFromPageTitle: function (cb) { return cb(false); },
    loadMyTaskViewMode: function (callback) {
        var viewMode = storage.myTaskViewMode ? storage.myTaskViewMode : 'myTask';
        callback(viewMode);
    },
    loadReportHistory: function (namespace, callback) {
        var key = "selectedReport".concat(namespace);
        callback(storage[key]);
    },
    loadReportSelectedUsers: function (namespace, callback) {
        var key = "reportSelectedUsers".concat(namespace);
        callback(storage[key] || {});
    },
    loadShownPaymentAlertTeamIds: function (callback) {
        callback(storage.shownPaymentAlertTeamIds || []);
    },
    loadStorage: function (key, callback) { return callback(storage[key] || null); },
    loadTaskManagementHistory: function (namespace, callback) {
        var key = "taskManagementHistory".concat(namespace);
        callback(storage[key]);
    },
    loadTeamId: function (callback) { return callback(storage.teamId); },
    openURL: function (url) { return postMessage({ action: 'openURL', url: url }); },
    reloadPage: function () { return postMessage({ action: 'reloadPage' }); },
    saveExportCharset: function () { },
    saveExportDataType: function () { },
    saveExportTimeFormat: function () { },
    saveFromPageTitle: function () { },
    saveHistory: function () { },
    saveMyTaskViewMode: function (viewMode) { return saveStorage('myTaskViewMode', viewMode); },
    saveReportHistory: function (namespace, selectedReport) {
        var key = "selectedReport".concat(namespace);
        saveStorage(key, selectedReport);
    },
    saveReportSelectedUsers: function (namespace, selectedUsers) {
        var key = "reportSelectedUsers".concat(namespace);
        saveStorage(key, selectedUsers);
    },
    saveShownPaymentAlertTeamIds: function (value) {
        saveStorage('shownPaymentAlertTeamIds', value);
    },
    saveStorage: saveStorage,
    saveTaskManagementHistory: function (namespace, history) {
        var key = "taskManagementHistory".concat(namespace);
        saveStorage(key, history);
    },
    saveTeamId: function (teamId) { return saveStorage('teamId', teamId); },
    token: { access_token: window.token },
    useHashRouter: true,
};
