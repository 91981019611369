/* eslint sort-keys: error */
import { redirectsForChromeExtension } from '@/constants/redirects';
var chrome = window.chrome;
var TimeCrowd = window.TimeCrowd;
export default {
    apiBase: window.apiBase,
    appVersion: '1.0.1', // APP_VERSION
    clearStorage: function () { },
    clearUser: function () {
        chrome.storage.local.get('auth', function (items) {
            chrome.storage.local.clear();
            chrome.identity.launchWebAuthFlow({ interactive: false, url: 'https://accounts.google.com/logout' }, function () { });
            chrome.identity.launchWebAuthFlow({ interactive: false, url: "".concat(window.apiBase, "/sign_out") }, function () { });
            TimeCrowd.api.removeAuthToken(items.auth).then(function () {
                location.href = '/html/popup.html';
            });
        });
    },
    getPage: function (cb) {
        TimeCrowd.google.getCurrentTab().then(function (tab) {
            cb(tab);
        });
    },
    loadExportCharset: function () { },
    loadExportDataType: function () { },
    loadExportTimeFormat: function () { },
    loadFromPageTitle: function (cb) {
        chrome.storage.local.get('fromPageTitle', function (items) {
            return cb(items.fromPageTitle || false);
        });
    },
    loadMyTaskViewMode: function (callback) {
        chrome.storage.local.get('myTaskViewMode', function (items) {
            return callback(items.myTaskViewMode);
        });
    },
    loadReportHistory: function (namespace, callback) {
        var key = "selectedReport".concat(namespace);
        chrome.storage.local.get(key, function (items) { return callback(items[key]); });
    },
    loadReportSelectedUsers: function (namespace, callback) {
        var key = "reportSelectedUsers".concat(namespace);
        chrome.storage.local.get(key, function (items) { return callback(items[key] || {}); });
    },
    loadShownPaymentAlertTeamIds: function (callback) {
        chrome.storage.local.get('shownPaymentAlertTeamIds', function (items) {
            return callback(items.shownPaymentAlertTeamIds || []);
        });
    },
    loadStorage: function (key, callback) {
        chrome.storage.local.get(key, function (items) {
            return callback(items[key] ? JSON.parse(items[key]) : null);
        });
    },
    loadTaskManagementHistory: function (namespace, callback) {
        var key = "taskManagementHistory".concat(namespace);
        chrome.storage.local.get(key, function (items) { return callback(items[key]); });
    },
    loadTeamId: function (callback) {
        chrome.storage.local.get('teamId', function (items) { return callback(items.teamId); });
    },
    openURL: function (url) { return chrome.tabs.create({ url: url }); },
    reloadPage: function () {
        for (var _i = 0, redirectsForChromeExtension_1 = redirectsForChromeExtension; _i < redirectsForChromeExtension_1.length; _i++) {
            var redirect = redirectsForChromeExtension_1[_i];
            if (location.pathname === redirect.to) {
                location.href = redirect.from;
                return;
            }
        }
        location.href = redirectsForChromeExtension[0].from;
    },
    saveExportCharset: function () { },
    saveExportDataType: function () { },
    saveExportTimeFormat: function () { },
    saveFromPageTitle: function (fromPageTitle) {
        return chrome.storage.local.set({ fromPageTitle: fromPageTitle });
    },
    saveHistory: function (pathname) { return chrome.storage.local.set({ pathname: pathname }); },
    saveMyTaskViewMode: function (myTaskViewMode) {
        return chrome.storage.local.set({ myTaskViewMode: myTaskViewMode });
    },
    saveReportHistory: function (namespace, selectedReport) {
        var _a;
        var key = "selectedReport".concat(namespace);
        chrome.storage.local.set((_a = {}, _a[key] = selectedReport, _a));
    },
    saveReportSelectedUsers: function (namespace, reportSelectedUsers) {
        var _a;
        var key = "reportSelectedUsers".concat(namespace);
        chrome.storage.local.set((_a = {}, _a[key] = reportSelectedUsers, _a));
    },
    saveShownPaymentAlertTeamIds: function (value) {
        chrome.storage.local.set({
            shownPaymentAlertTeamIds: value,
        });
    },
    saveStorage: function (key, value) {
        var _a;
        chrome.storage.local.set((_a = {},
            _a[key] = value,
            _a));
    },
    saveTaskManagementHistory: function (namespace, taskManagementHistory) {
        var _a;
        var key = "taskManagementHistory".concat(namespace);
        chrome.storage.local.set((_a = {}, _a[key] = taskManagementHistory, _a));
    },
    saveTeamId: function (teamId) { return chrome.storage.local.set({ teamId: teamId }); },
    token: { access_token: window.token },
    useHashRouter: false,
};
