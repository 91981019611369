/* eslint sort-keys: error */
import dateFnsLocale from './date-fns/locale/en';
export default {
    actions: {
        change: 'Change',
    },
    calendar: {
        addToTimelineWithTime: 'Add at {{time}}',
        osTimeZone: 'Your environment',
        timeCrowdTimeZone: 'TimeCrowd',
        timeEntryUpdated: 'Time entry was successfully updated.',
        timeZoneDifference: 'The time zone of TimeCrowd and the time zone of your environment are different. Since there is a possibility that trouble may occur in the calendar display etc., please change the time zone of TimeCrowd according to the time zone of the your environment.',
    },
    calendarIntegrationWorkspaceSetting: {
        allTeamsOwnerAuthorization: 'Owner permissions for all teams',
        authorizationDescription: 'You must have all of the following permissions to set up this feature.',
        currentPrivilegedUsers: 'Privileged users',
        descriptionHtml: 'If a keyword is included in the title of an appointment, it will be saved as an imprint of the specified category.<br>All conditions in the workspace should have different keywords.',
        label: 'Automatic category assignment to all team members',
        teamFilterLabel: 'Sorting conditions',
        workspaceAuthorization: 'Owner permissions for this workspace',
    },
    card: {
        modal: {
            body: '<p>Credit card change completed successfully.</p>',
            header: 'Credit card change accepted.',
        },
    },
    confirmLeaveTeamMyselfModal: {
        confirmMessage: 'We are removing you from the team.Are you sure you want to do this?',
        descriptionFooter: 'To view the team information again, you will need to be invited by another team owner.',
        descriptionHeader: 'If you are removed from a team, you will not be able to perform all of the following team-related operations',
        leaveTeam: 'Remove yourself from the team.',
        leaveTeamSuccess: 'You have left %{teamName}.',
        managerDescription1: 'Invite and confirm team members',
        managerDescription2: 'View and edit tasks for you and your team',
        managerDescription3: 'View reports of the team, including yours',
        memberDescription1: 'View and edit team tasks',
        memberDescription2: 'View your report',
        memberDescription3: 'View your team members',
    },
    csv: {
        categoryId: 'Category id',
        categoryTitle: 'Category name',
        dateTimeFormat: 'ddd, DD MMM YYYY HH:mm:ss ZZ',
        hms: '%{h}h %{m}min %{s}s',
        taskAmount: 'Amount (yen)',
        taskId: 'Task ID',
        taskState: 'Status',
        taskTitle: 'Task name',
        taskTotalTime: 'Total time',
        taskUrl: 'Task URL',
        teamId: 'Team ID',
        teamName: 'Team name',
        timeEntryAmount: 'Amount (yen)',
        timeEntryComment: 'Comment',
        timeEntryDuration: 'Duration',
        timeEntryId: 'Time Entry ID',
        timeEntryStartedAt: 'Started at',
        timeEntryStoppedAt: 'Stopped at',
        userId: 'User ID',
        userNickname: 'User name',
    },
    deleteTaskModal: {
        additional: '(When deleted, it is saved in "Trashed" status of "Task Management", but it will be deleted completely after 2 weeks.)',
        attention: 'If other members also record with the same task, not only their own time entries but also the time entries of other members are deleted.',
        confirmation: 'Are you sure to delete the below task?',
        title: 'Delete a task',
    },
    deleteTimeEntryModal: {
        attention: 'If deleted a time entry, you cannot restore that.',
        confirmation: 'Are you sure to delete the below time entry?',
        title: 'Delete a time entry',
    },
    duration: {
        hours: 'h',
        minutes: 'min',
        seconds: 's',
    },
    errors: {
        contactUs: 'If you have any questions, please choose one of the contact methods below and let us know.',
        description: {
            403: 'You are not authorized to access this page.',
            404: "The page you were looking for doesn't exist.",
            500: 'Please try again later.',
        },
        failedFetchPeriods: 'Failed to fetch periods. Please reload the browser.',
        forbidden: 'You are not authorized.',
        internalServerError: 'An error has occurred. Please wait a while and try again.<br>If the issue is not resolved, please contact us via "Contact".',
        network: 'An error occurred with network. Please retry.',
        notFound: 'Resource not found. Please reload the page.',
        title: {
            403: 'Forbidden',
            404: 'Page not found',
            500: 'An unexpected error occurred',
        },
        tooLarge: 'Data size exceeds 1MB.',
    },
    export: {
        allPeriods: 'All periods',
        allTeams: 'All Teams',
        allUsers: 'All Users',
        blankDescription: '',
        blankTitle: 'No csv data',
        by: {
            task: 'By Task',
            time_entry: 'By Time Entry',
        },
        charset: {
            label: 'Charset',
            sjis: 'Shift_JIS',
            utf8: 'UTF-8',
            utf8_bom: 'UTF-8 (with BOM)',
        },
        createdAt: 'Created at',
        dataType: 'Data type',
        days: 'days',
        deleteCsv: {
            attention: 'Delete a selected CSV data permanently.',
            header: 'Delete a CSV',
        },
        example: 'Example: ',
        examples: {
            '6min': '1.1',
            hms: '1h 02min 01s',
            hms_colon: '1:02:01',
            seconds: '3721',
        },
        fileType: 'File type',
        format: {
            '6min': '0.1 point per 6 minutes',
            hms: 'Hours, minutes, seconds (Splited by word)',
            hms_colon: 'Hours, minutes, seconds (Splited by colon)',
            seconds: 'Seconds',
        },
        generate: 'Generate',
        generateNewData: 'Generate new data',
        lastMonth: 'Last month',
        lastWeek: 'Last week',
        lastYear: 'Last year',
        multipleTeams: 'Multiple Teams',
        multipleUsers: 'Multiple Users',
        noTeams: 'No Teams',
        noUsers: 'No Users',
        notice: {
            '6min': '* Since the output is rounded up, there may be a difference between the total values ​​for "By Task" and "By Time Entry".',
            hms: '',
            hms_colon: '',
            seconds: '',
        },
        notify: {
            cancel: 'CSV export was canceled',
            failure: 'CSV export was failed.',
            in_progress: 'CSV export was started.',
            initial: 'CSV export was started.',
            success: 'CSV export was succeeded.',
        },
        range: 'Range',
        target: 'Target',
        thisMonth: 'This month',
        thisWeek: 'This week',
        thisYear: 'This year',
        timeFormat: 'Time format',
        to: 'to',
        today: 'Today',
        trialDuring: "In the next %{period} days,  <strong className='mr-1'>%{name}</strong> will end free trial period of 2 weeks. When you finish, you can not use data export.",
        trialEndedToMember: "<strong className='mr-1'>%{name}</strong> has ended free trial period. We are restricting to export data.",
        trialEndedToOwner: "<strong className='mr-1'>%{name}</strong> has ended free trial period.<br />In order to continue to export data, it is necessary to move to a pay plan.",
        yesterday: 'Yesterday',
    },
    hardDeleteTaskModal: {
        confirmation: 'I understood about the above.',
        description: 'Are you sure to delete the below task? (When deleted forever, it cannot be restored.)',
        title: 'Delete a task forever',
    },
    infiniteCalendar: {
        blank: 'Select a date...',
        headerFormat: 'ddd, MMM Do',
        locale: dateFnsLocale,
        todayLabel: {
            long: 'Today',
        },
        weekStartsOn: 0,
        weekdays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    },
    integration: {
        chatwork: 'Automatically create categories with chat name (Chrome extension)',
        notFoundIntegrations: 'No external services were found that can be linked.',
    },
    lang: '',
    mainmenu: {
        members: 'Members',
        more: 'More',
        mytask: 'My Task',
        report: 'Report',
        workspaceSettings: 'Workspace Settings',
    },
    member: {
        blankDescription: '',
        blankTitle: 'No active members',
        nonWorking: 'Non Working',
        realtime: 'Realtime',
        timeline: 'Today',
    },
    members: {
        addCapacity: 'Add capacity',
        memberCountLabel: 'Members',
        memberCountUnit: 'people',
        seatLeft: '%{count} seat left',
    },
    model: {
        attributes: {
            team: {
                defaultDuration: 'Default Duration',
                name: 'Team Name',
            },
        },
    },
    mydatebar: {
        today: 'Today',
    },
    mytask: {
        blankDescriptipn: "Let's make it",
        blankTitle: 'No tasks',
    },
    other: {
        changePassword: 'Change Password',
        dataExport: 'Export',
        personalSettings: 'Personal Settings',
        reportRecipients: 'Report Recipients',
        signOut: 'Sign out',
        taskManagement: 'Task Management',
    },
    payment: {
        applyCreditCard: 'Payment with credit card',
        changeLater: 'You can change it later',
        changeMaximumNumber: 'Change maximum number',
        chromeExtension: {
            description: 'You can not run a billing process from the Chrome extension. Please move to the PC site from the link below and perform the billing process.',
            goToPC: 'Move to PC site',
        },
        creditCardSettings: 'Credit Card Settings',
        currently: 'Current',
        explainPaymentDeadline: 'This team will be billed for payment on the {{date}} th of every month.',
        history: 'History',
        maximum: 'Maximum',
        numberOfPeople: 'Number of people',
        payjp: {
            submitText: 'Save',
            text: 'Change credit card to pay',
        },
        paymentMethods: 'Payment methods',
        people: 'people',
        setUpperLimit: 'Set an upper limit',
        taxIncluded: 'Tax included',
        totalAmount: 'Total amount',
        trialRemaining: 'Trial remaining: {{days}} days',
        upToPeople: 'people',
    },
    personalSetting: {
        appConnectGoogleAccountNotice: 'You can not connect with Google Account from App. Please use the browser to connect with Google Account.',
        backupCodeAlreadyDownloaded: 'Already downloaded',
        backupCodeDesc: 'It can be used instead of a OTP. You can only download it once. Each code will be invalidated once you use it to sign in.',
        connectAppleAccounts: 'Add another Apple Account',
        connectGoogleAccounts: 'Add another Google Account',
        connectOutlookAccounts: 'Add another Microsoft Account',
        connectSamlAccounts: 'Add another SAML Account',
        contactInfo: 'Contacts',
        crxConnectGoogleAccountNotice: 'You can not connect with Google Account from Chrome Extension. Please move to the PC site from the link below and make connect with Google Account.',
        deactivate: 'Deactivate',
        deactivation: {
            attention: 'After you deactivate, your data will be handled as follows',
            attentionDetail1: 'Your personal information, except for your name, will be modified within TimeCrowd so that you are not personally identifiable.',
            attentionDetail2: 'Basically, the time entry remains as a record of the exited user.',
            attentionDetail3: 'However, the entire work history of an individual team will be deleted.',
            checkHelpPage: 'Please check the help page for details.',
            confirmation: 'I understood about the above.',
            yourEmailHtml: 'Your email address: <b>%{email}</b>',
        },
        destination: 'Destination',
        goToPC: 'Go to pc site',
        otpDesc: 'Scan the QR code below with the two-factor authentication app, enter the displayed OTP, and press the save button.',
        receiveDailyReport: 'Receive daily report',
        receiveWeeklyReport: 'Receive weekly report',
        receiveWeeklyReportDesc: 'Includes time entries from previous Monday to previous Sunday.',
        reportMail: 'Report mail',
        requiredTwoFactorAuth: 'Enabling',
        requiredTwoFactorAuthDesc: 'Two-factor authentication is required to sign in',
        requiredTwoFactorAuthLabel: 'Enable two-factor authentication',
        sendSettings: 'Send setttings',
        timeOfReceivingDailyReport: 'Time to send daily report',
        timeOfReceivingDailyReportDesc: 'Includes time entries from 24 hours ago until the set time.',
        twoFactorAuth: 'Two factor auth',
    },
    proxyInputUser: {
        label: 'Time entry added by team owner',
        message: 'The time entry is being entered on behalf of %{nickname}.',
        notification: 'The time entry was added on behalf of %{nickname}.',
    },
    quicktask: {
        appointment: 'Appointment',
        category: 'Category',
        comment: 'Comment',
        freeTime: 'Free time',
        fromPage: 'From Page',
        insertTaskTitle: 'Enter a task title',
        newtask: 'Start or add a task',
        start: 'Start',
        stop: 'Stop',
        task: 'Task',
        team: 'Team',
        withoutTimeEntry: 'Unspecified',
    },
    report: {
        allCategory: 'All Category',
        blankDescription: 'Click <a href="https://help.timecrowd.net/report" target="_blank">here</a> for the help page.',
        blankDescriptionForPaymentMember: 'Only team owners can switch to a paid plan',
        blankDescriptionForPaymentPrefix: 'Click',
        blankDescriptionForPaymentSuffix: 'to switch to a paid plan',
        blankTitle: 'No report data',
        blankTitleForPayment: "The report is can't be displayed because the trial has expired",
        byCategory: 'By Category',
        byUser: 'By User',
        csv: {
            entries: 'CSV (Time entries)',
            task: 'CSV (Tasks)',
        },
        details: 'Details',
        download: 'Download',
        download_csv: 'Download CSV',
        duration: 'Duration',
        hideInactiveMembers: 'Hide inactive members',
        hours: 'Hours',
        orderedByCategories: 'ordered by categories',
        orderedByTotalTime: 'ordered by total time',
        period: 'Period',
        ratio: 'Category ratio',
        series: 'Time series',
        showInactiveMembers: 'Show inactive members',
        team: 'Team',
        trialDuring: "In the next %{period} days,  <strong className='mr-1'>%{name}</strong> will end free trial period of 2 weeks. When you finish, the report cannot be displayed.",
        trialEndedToMember: "<strong className='mr-1'>%{name}</strong> has ended free trial period. We are restricting the display of the report.",
        trialEndedToOwner: "<strong className='mr-1'>%{name}</strong> has ended free trial period.<br />In order to continue to check the report, it is necessary to move to a pay plan.",
        user: 'User',
        you: 'You',
    },
    reportRecipients: {
        notFound: 'Recipients of Report was not found',
        registered: 'Registered recipients',
        title: 'Report Recipients',
    },
    shared: {
        aboutPlans: 'Abount plans',
        account: 'Account',
        add: 'Add',
        addChildTeam: 'Add a child team',
        addFiltering: 'Add filtering',
        addReminder: 'Add reminder',
        addReminderIgnore: 'Add ignore',
        addTask: 'Add a task',
        addTeamEmploy: 'Add hourly rate for team',
        addTimeEntry: 'Add Time Entry',
        addWorkspaceEmploy: 'Add hourly rate for workspace',
        all: 'All',
        allBelongTeams: 'All belonging teams',
        allCategories: 'All categories',
        allPeriods: 'All periods',
        allTeams: 'All teams',
        allUsers: 'All Users',
        amount: 'Amount',
        apply: 'Apply',
        archive: 'Archive',
        archiveCategoryModalDescription: {
            archive: 'The following categories will be archived.',
            unarchive: 'The following categories will be unarchived.',
        },
        archiveCategoryModalTitle: {
            archive: 'Archive Category',
            unarchive: 'Unarchive Category',
        },
        archived: 'Archived',
        archivedTaskCannotBeEdited: 'Tasks in archived category cannot be edited',
        archivedTaskCannotBeStarted: 'Tasks in archived category cannot be started',
        asOf: '%{time}',
        autoStart: 'Auto start time tracking',
        autoStartLabel: 'Auto start/stop with Calendar events',
        autoStopTimeTracking: 'Auto stop time tracking',
        avatarHelp: 'JPG/PNG/GIF format, 1MB or less',
        back: 'Back',
        backupCode: 'Backup code',
        beta: 'β',
        between: '-',
        bouncedRecipient: 'We could not send the report mail. Please check your e-mail.',
        bulkCreateCategoriesAndTasks: 'Bulk create categories and tasks',
        bulkCreateWithLikeBreak: 'You can create multiple tasks with line breaks. (up to 100 tasks)',
        businessDay: 'Business day',
        calculation: 'Calculation',
        calendar: 'Calendar',
        calendarIntegrationSelectWorkspace: 'Select the workspace to which you want to apply the settings',
        calendarIntegrationSettingsBaseTypeDescriptionHtml: 'You can choose to set your own calendar synchronization settings or follow those set by a single workspace.<br />You cannot apply multiple settings at once.',
        calendarIntegrationSettingsBaseTypePersonal: 'Personal',
        calendarIntegrationSettingsBaseTypeWorkspace: 'Workspace',
        calendarIntegrationSettingsPriorityDescription1: 'Priority determines which sorting rule is applied first when calendar appointments partially contain the same keywords. ',
        calendarIntegrationSettingsPriorityDescription2: 'Priorities are applied to the entire workspace, not to individual teams, so if multiple teams are creating conditions, the priorities will not necessarily be sequentially numbered. ',
        calendarIntegrations: 'Calendar Integrations',
        calendarSettings: 'Calendar settings',
        calendarViews: {
            daily: 'Day',
            weekly: 'Week',
        },
        calendarZoomRate: 'Calendar zoom rate',
        canChangeLater: 'You can change it later.',
        canDoLater: 'You can do it later.',
        cancel: 'Cancel',
        cancelIntegration: 'Cancel integration',
        canceled: 'Canceled',
        cannotBeStopped: 'This operation cannot be stopped',
        cannotReorderWhileSearching: 'Cannot reorder while searching',
        cannotShowMenuForTeam: 'You do not have permission to access %{menu} of %{team}. Display the members view.',
        capacityModalBody: 'The capacity settings have been updated.',
        capacityModalTitle: 'Capacity settings',
        category: 'Category',
        categoryListSearchPlaceholder: 'Category name',
        categoryManagement: 'Category management',
        categorySettings: 'Category settings',
        categoryTaskStructureExportHistory: 'CSV file export history',
        categoryTaskStructureExportNote: 'Export categories and tasks as a CSV file',
        categoryTaskStructureImportHistory: 'CSV file import history',
        categoryTaskStructureImportNote1: 'You can create categories and tasks in bulk by importing a CSV file',
        categoryTaskStructureImportNote2: 'The CSV file template is ',
        caution: 'Caution',
        change: 'Change',
        changeEmail: 'Change email',
        changeEmailDescription: 'A confirmation instructions will be sent to your new email.',
        changeLinkedAccount: 'Change the linked account',
        changeTask: 'Change task',
        chatwork: 'ChatWork',
        clear: 'Clear',
        clickHereForDetails: 'Click here for details',
        clickHereToPay: 'Click here to apply for a paid plan',
        close: 'Close',
        companyName: 'Company name',
        completed: 'Completed',
        confirm: 'Confirm',
        confirmation: 'I understood about the above.',
        confirmationEmailSent: 'A confirmation email has been sent. Please click the link in the email.',
        contactForExtendTrial: 'Contact for trial period extension',
        contactSupport: 'Contact',
        contactViaChatwork: 'Contact us via ChatWork',
        contactViaEmail: 'Contact us via Email',
        copied: 'Copied',
        copy: 'Copy',
        copyOf: 'Copy of %{title}',
        countOfFilters: {
            one: '%{count} filter',
            other: '%{count} filters',
            zero: '%{count} filer',
        },
        create: 'Create',
        createA: 'Create %{name}',
        createCalendarIntegrationFilterSuccess: 'Filtering was successfully added.',
        createChildTeam: 'Create a child team',
        createOrJoinTo: 'Create a new %{target} or join the %{target} from the invitation link.',
        createPayment: 'Complete your payment',
        createPaymentDescription: 'Your payment has not been completed',
        createReportBookmarkDescription: 'Bookmark this conditions. It will not be visible to other members.',
        createReportBookmarkHeader: 'Bookmark the conditions',
        createReportBookmarkTitleLabel: 'Enter a title for the bookmark (optional)',
        createReportBookmarkTitlePlaceholder: 'Monthly work of John Doe, personnel expenses of projects A and B this year',
        createTeam: 'Create team',
        createdWithName: 'A %{target} will be created with this name.',
        crossTeamReport: 'Cross-team report',
        crossTeamReportLoadingDescription: 'Please wait while the report is being created',
        crossTeamReportLoadingTitleForLongTerm: 'Preparing long-term report',
        crossTeamReportLoadingTitleForRealtime: 'Creating realtime report',
        csv: 'CSV',
        currencyUnit: '¥',
        currentEmail: 'Current email',
        currentPlan: 'Current plan',
        currentQuarter: 'Current quarter',
        currentRate: 'Current rate',
        customFieldDetails: 'Custom field details',
        customFieldList: 'Custom field list',
        customFields: 'Custom fields',
        customFieldsDescription1: 'Configure custom fields for categories.',
        customFieldsDescription2: 'Make sure custom field names are unique.',
        customFieldsExpression: 'Expression',
        customFieldsExpressionCalculating: 'Calculating',
        customFieldsExpressionErrors: {
            master_not_found: 'Referenced custom field does not exist',
            unexpected_error: 'Unexpected error occurred',
            value_not_found: 'Referenced custom field is not filled',
            zero_division_error: 'Cannot divide by zero',
        },
        customFieldsExpressionLabels: {
            customField: 'custom_field',
            totalAmount: 'total_amount',
            totalDuration: 'total_duration',
        },
        customFieldsExpressionPlaceholder: 'Please enter an expression',
        customFieldsName: 'Name',
        customFieldsNotAllowed1: 'This feature is for Standard Plan and Enterprise Plan.',
        customFieldsNotAllowed2: 'Set project id for internal management',
        customFieldsNotAllowed3: 'Set estimates and implement budget and actual management',
        customFieldsNotAllowed4: 'If you are interested in how to use the above, we can also explain details. Please feel free to contact us.',
        customFieldsReachedLimit1: 'You have reached your custom field limit.',
        customFieldsReachedLimit2: 'If you would like to add more, please contact us.',
        customFieldsRequirementAll: 'Required',
        customFieldsRequirementLeaf: 'Only leaf categories',
        customFieldsType: 'Type',
        customFieldsTypes: {
            duration: 'Duration',
            expression: 'Expression',
            number: 'Number',
            text: 'Text',
        },
        customFieldsUnique: 'Unique',
        customFieldsUnit: 'Unit',
        customFieldsValuesDuplicated: 'Duplicated values already exists. Please update the values and try again.',
        customFieldsValuesInvalid: 'The following custom fields values ​​are invalid',
        customFieldsVisibilities: {
            all: 'All',
            owner: 'Owner',
        },
        customFieldsVisibility: 'Visibility',
        daily: 'Daily',
        date: 'Date',
        datetime: 'Time',
        deactivated: 'Deactivated',
        defaultTeamCategory: 'Default team/category',
        delete: 'Delete',
        deleteCalendarIntegrationFilterSuccess: 'Filtering was successfully deleted.',
        deleteCustomField: 'Delete custom field',
        deleteCustomFieldConfirm: 'I have confirmed that the above custom field and input values will be deleted. ',
        deleteCustomFieldDescription: 'The following custom fields and input values will be deleted.',
        deleteEmptyTask: 'No time entry. Are you sure you want to delete this task?',
        deleteFiltering: 'Delete filtering',
        deleteFilteringDescription: 'Are you sure you want to delete the following filtering?',
        deleteForever: 'Delete Forever',
        deletePayment: 'Cancel payment',
        deletePaymentDescription1: 'If you stop billing, you will not be able to use the functions of the light plan.',
        deletePaymentPermission: 'Payment can only be canceled by the paid user.',
        deleteReminderDescription: 'Are you sure you want to delete the reminder?',
        deleteReminderEmailsSetting: 'Delete reminder emails setting',
        deleteReportBookmarkDescription: 'Are you sure you want to delete the following bookmark?',
        deleteReportBookmarkHeader: 'Delete the bookmark',
        deleteReportBookmarkNotice: '* The data of the selected condition will not be deleted.',
        deleteWorkspace: 'Delete workspace',
        deleteWorkspaceConfirm: 'I have confirmed that the above workspace, team, categories belonging to it, tasks, and time entries will all be deleted.',
        deleteWorkspaceDescription: 'The team created in the workspace and all data recorded in the team will be deleted.',
        details: 'Details',
        disabledEmployNotice: 'If the settings are duplicated, the team settings will be used instead of the workspace settings.',
        disconnect: 'Disconnect',
        disconnectNote: 'Calendar integration will be canceled',
        disconnectTitle: 'Stop Calendar Integration',
        disconnectedIdentity: 'The account was successfully deauthenticated.',
        doCreateNew: 'Create a new %{target}',
        done: 'Done',
        dontShowAgain: "Don't show again",
        dontShowThisMessage: "Don't show this message",
        download: 'Download',
        dragAndDropFile: 'Drag and drop a file here',
        duration: 'Duration',
        edit: 'Edit',
        editNamedTask: 'Edit task "%{name}"',
        editTaskConfirmModalDescription1: 'Change the task.',
        editTaskConfirmModalDescription2: 'The entries of the following users associated with this task will also be changed.',
        editTaskConfirmModalTitle: 'Change task',
        editTeam: 'Edit team',
        email: 'E-Mail',
        emailConfirmationCanceled: 'Your email address change has been cancelled.',
        emailConfirmationResent: 'A confirmation instructions has been resent. Please check your email.',
        emailConfirmationSent: 'A confirmation instructions has been sent. Please check your email.',
        employByTeams: 'Rate settings by teams',
        employSettingsForTeam: 'Rate settings for team',
        employSettingsForWorkspace: 'Rate settings for workspace',
        enterComments: 'Please enter comments',
        export: 'Export',
        exportCSVFile: 'Export CSV file',
        exportCategoriesAndTasks: 'Export categories and tasks',
        exportComplete: 'Export complete',
        exportFailed: 'Export failed',
        exportFromTeam: 'Export from the team below',
        exporting: 'Exporting',
        exportingNow: 'Exporting now. ',
        failed_invalid_data: 'Invalid Data error',
        failed_lines_limit: 'Lines limit error',
        failed_malformed: 'Malformed CSV error',
        failed_unexpected: 'Unexpected error',
        failure: 'Failure',
        favorite: 'Favorite',
        filename: 'Filename',
        filter: 'Filter',
        filterAndSelect: 'Filter',
        filtering: 'Filtering',
        filteringNote: 'If the event title contains keywords, it will be saved for the specified team category.',
        firstFetchIsNotFinished: 'Fetching calendar events. Please wait.',
        fiscalYearly: 'Fiscal year',
        followingCategoriesDuplicated: 'The following categories are duplicated',
        fromDate: 'from {{date}}',
        fromWorkspace: 'From workspace',
        fromWorkspaceDescription: 'This rate was set by the workspace owner.',
        generalSettings: 'General Settings',
        generateLatestReport: 'Generate latest report',
        getInvitationURL: 'Get the invitation URL from the %{target} owner and join the %{target}.',
        getTitleAndURLfromPage: 'Get title and URL from current page',
        goToCalendarIntegration: 'Go to calendar integration',
        goToCategorySettings: 'Go to category settings',
        goToLegacyReport: 'Go to the legacy report',
        goToSettings: 'Go to settings',
        goToTeamReport: 'Go to team report',
        hasOverlapTimeEntry: {
            one: 'There is %{count} duplicate',
            other: 'There are %{count} duplicates',
        },
        help: 'Help',
        here: 'Here',
        hours: ' hours ',
        hours_short: ' h ',
        icon: 'Icon',
        ignoreCalendarNote: 'If the event title contains keywords, it will not be displayed on TimeCrowd',
        ignoreMinutes: 'Minutes',
        ignoreMinutesLabel: 'Enabled',
        ignoreMinutesNote: 'Ignore events longer than the setting on the left',
        ignoreWords: 'Ignore by words',
        import: 'Import',
        importCSVFile: 'Import CSV file',
        importComplete: 'Import complete',
        importFailed: 'Import failed',
        importIntoTeam: 'Import into the team below',
        importingCSVFile: 'Importing CSV file',
        inTeam: 'in Team',
        inactive: 'Inactive',
        includeArchived: 'Include archived',
        includeHolidays: 'Include holidays',
        inputNextStartTime: 'Input the next start time',
        inputPrevStopTime: 'Input the previous stop time',
        integrateWithGoogleCalendar: 'Integrate with Google Calendar',
        integrateWithMyTask: 'Integrate My Task with External Calendar',
        integrateWithOutlookCalendar: 'Integrate with Outlook Calendar',
        invalidCapacity: 'Invalid capacity',
        invitationDisallowed: 'Invitation disallowed',
        invitationDisallowedDescription: 'The workspace owner (administrator) can allow the team owner to invite new members. Details are below.',
        invitationLinkTo: 'Invitation link to %{name}',
        invitationUrlFor: 'Invitation URL for %{name}',
        invite: 'Invite',
        inviteMembers: 'Invite members',
        inviteMembersToTeam: 'Invite members to the team',
        inviteNewMember: 'Invite new members',
        inviteToTeam: 'Invite to the team',
        joinExisting: 'If you want to join an existing %{target}',
        joinTo: 'Join the %{target}',
        keywords: 'Keywords',
        lastFiscalYear: 'Last fiscal year',
        lastMonth: 'Last month',
        lastQuarter: 'Last quarter',
        lastUpdated: 'Last updated',
        lastWeek: 'Last week',
        leaveComment: 'Leave a comment',
        leaveParentTeam: 'Leave parent team',
        leaveWorkspace: 'Leave workspace',
        leaved: 'Leaved',
        linkedAccount: 'Linked account',
        linkedCalendar: 'Linked calendar (Required)',
        list: 'List',
        loggedInAsSupportAccount: 'Logged in as support account',
        loginAccount: 'Login account',
        longTermReport: 'Long-term report',
        longTermReportCacheDescription: 'It can be displayed with almost no waiting time. Data can be updated later.',
        longTermReportCacheLabelMain: 'Speed priority',
        longTermReportCacheLabelSub: 'Create with the previous data',
        longTermReportCacheTitle: 'Data as of %{date} was found',
        longTermReportDescription1: 'With long-term reports, you can create monthly, quarterly, and annual reports.',
        longTermReportDescription2: 'If you are creating data for the first time or if there is a large amount of data during the period, it may take several times to create.',
        longTermReportGenerateDescription: 'It may take several minutes to several tens of minutes, but we will create a report with the latest data.<br>During that time, the data creation will proceed even if you view another screen. We will inform you on the screen when it is completed.',
        longTermReportGenerateLabelMain: 'Freshness priority',
        longTermReportGenerateLabelSub: 'Create with the latest data',
        longTermReportGenerateNotice: 'You can reuse the data for the same period from the next time and speed up the display.',
        longTermReportGenerateTitle: 'Since this is the first period to be generated, it will be generated with the latest data.',
        longTermReportGenerated: 'Long-term report has been created!',
        longTermReportGeneratingCancel: 'Cancel long-term report creation',
        longTermReportGeneratingDescription: 'If you want to create a long-term report for a new period, please cancel.',
        longTermReportGeneratingTitle: 'Currently creating long-term report',
        longTermReportLoadingDescription: 'Data creation will proceed even if you view another screen',
        longTermReportLoadingStatus: {
            pending: 'Preparing...',
            processing: 'Creating report...',
        },
        longTermReportLoadingTitle: 'Creating long-term report',
        longTermReportShow: 'Show long-term report',
        makeNotWorkspaceOwner: 'Removed %{name} as an workspace owner',
        makeWorkspaceOwner: 'Made %{name} an workspace owner',
        manageChildTeams: 'Manage child teams',
        manageMembers: 'Manage members',
        manageTeams: 'Manage teams',
        maxTeamCountNotice: {
            link: 'contact us',
            prefix: 'If you want to add more teams, please ',
            suffix: '.',
        },
        member: 'Member',
        memberListSearchPlaceholder: 'Member name',
        memberManagement: 'Member management',
        memberSearchPlaceholder: {
            realtime: 'Member, team, category, task name',
            schedule: 'Member, team name',
        },
        minutes: ' minutes',
        minutes_short: ' m',
        month: '%{month}',
        monthBeforeLast: 'Month before last',
        monthly: 'Monthly',
        moveToPersonalSettings: 'Move to personal settings',
        multiwordsNote: '* keywords: You can set mutiple keywords with line breaks.',
        mustBeUniqueKeywords: {
            one: 'The %{keywords} is a duplicate of a keyword in an existing condition. Please set another keyword.',
            other: 'The %{keywords} are duplicates of keywords in %{count} existing conditions. Please set another keyword.',
        },
        myReport: 'My Report',
        myReportNotice: 'You can check your own records in My Report',
        nEntries: '%{num} entries',
        nMembers: '%{num} members',
        nPeople: '%{num} people',
        nTeams: '%{num} teams',
        name: 'Name',
        newCustomField: 'New custom field',
        newEmail: 'New email',
        newTeam: 'New team',
        next: 'Next',
        nextDay: 'Tomorrow',
        noCustomFieldFound: 'No custom field found',
        noHistoryFound: 'No history found',
        noMoreTasksForCategory: 'No more tasks in this category',
        noReportBookmarkFound: 'No bookmark found',
        noSetting: 'No setting',
        noTasksForCategory: 'No tasks in this category',
        noTasksForKeyword: 'No tasks matched the entered keyword',
        noTeam: 'No team',
        noTeamFound: 'No team found',
        notFound: {
            back: 'Back',
            description: 'Perhaps you misspelled the url or it has been moved or removed',
            title: "The page you're looking for can not be found.",
        },
        notSelected: 'Not selected',
        notWorking: 'Not working',
        notesForCharges: '* It will be displayed the day after the payment is executed.',
        notesForUnconfirmedEmailHtml: 'A confirmation instructions has been sent to %{email}. <br>Please check your email.',
        notifyWhenComplete: 'We will notify %{email} when the process is complete',
        now: 'Now',
        numberOfTeams: 'Number of teams',
        openTimeCrowdOnBrowser: 'Open the browser version',
        operationRestrictedByAdministrator: 'Operation restricted by administrator',
        or: 'or',
        others: 'Others',
        othersShort: 'others',
        otp: 'OTP',
        outlookCalendar: 'Outlook Calendar',
        paidUser: 'Paid user',
        paymentDeleted: 'Payment was successfully canceled.',
        paymentIsFailed: 'Payment failed with this credit card. Please renew your card or register another card.',
        personal: 'Personal',
        personalSettings: 'Personal Settings',
        personalUse: 'Personal use',
        phoneNumber: 'Phone number',
        plan: 'Plan',
        pleaseChangeAccountWithBrowser: 'Changing account can be done on the browser version of TimeCrowd.',
        pleaseConfigureCategory: 'The category of Calendar integration is deleted or archived. Please configure.',
        pleaseConfigureWorkspace: 'The workspace of Calendar integration setting has been canceled. Please reconfigure the settings.',
        pleaseConfigureWorkspacesCalendarIntegrationFiltersCategory: 'The category of Workspace Calendar integration is deleted or archived. Please configure.',
        pleaseIntegrateWithBrowser: 'Calendar integration can be done on the browser version of TimeCrowd.',
        pleaseReloadPage: 'A newer version may have been released. Please reload the page.',
        pleaseSelect: 'Pelase select',
        pleaseSelectCalendarIntegrationSettingWorkspace: 'Please select a workspace to apply the settings',
        pleaseSelectCategory: 'Please select category',
        pleaseSelectCategoryOfCalendarIntegrationFilterHtml: {
            one: 'In the %{label}, there is one condition with no category selected.<br> Please set a category for the condition or delete it if it is unnecessary.',
            other: 'In the Filtering, there is %{count} conditions with no category selected.<br> Please set a category for the condition or delete it if it is unnecessary.',
        },
        pleaseSelectList: 'Please select calendar',
        pleaseSelectOneOwner: 'Please select one or more users with owner role.',
        preaseSelectTeam: 'Please select a team',
        priority: 'Priority',
        processingResult: 'Result',
        profile: 'Profile',
        quarterly: 'Quarterly',
        rate: 'Rate',
        rateHelpForTeam: 'You can set the rate for time tracking in this team. This rate takes precedence over the workspace settings.',
        rateHelpForWorkspace: 'The set rate is used for time tracking in all teams in the workspace.<br>If there is a rate for each team, it will be used.',
        readMore: 'Read more',
        realtimeReport: 'Realtime report',
        realtimeReportDescription: 'Realtime report allows you to view the latest data for up to 3 months.',
        recent12Months: 'Recent 12 months',
        recent2FiscalYears: '2 years',
        recent2Months: 'Recent 2 months',
        recent2Weeks: 'Recent 2 weeks',
        recent6Months: 'Recent 6 months',
        recentCategories: 'Recent categories',
        recentCategoriesProxy: 'Recent categories by %{nickname}',
        recentTasks: 'Recent tasks',
        recentTasksProxy: 'Recent tasks by %{nickname}',
        recommended: 'Recommended',
        refreshing: 'Refreshing',
        refunded: 'Refunded',
        relatedTasks: 'Related tasks',
        reload: 'Reload',
        reminderCondition: 'Condition',
        reminderConditions: {
            none: 'None',
            not_working: 'Not working',
            working: 'Working',
        },
        reminderDay: 'Day of the week',
        reminderDays: {
            /* eslint-disable sort-keys */
            monday: 'M',
            tuesday: 'T',
            wednesday: 'W',
            thursday: 'T',
            friday: 'F',
            saturday: 'S',
            sunday: 'S',
            /* eslint-enable sort-keys */
        },
        reminderEmails: 'Reminder email',
        reminderEmailsDescription1: "A reminder email will be sent to each member's registered email address.",
        reminderEmailsDescription2: 'Reminder type',
        reminderEmailsDescription3: 'General Purpose: Sends a message at the set time.It is also possible to condition whether members are working.',
        reminderEmailsDescription4: 'Forgetting to stop: Send a reminder when there is a work time that has passed more than the set time.',
        reminderEmailsDescription5: 'Previous business day: Send a reminder when the total time of the previous business day is less than the set time.',
        reminderIgnore: 'Ignores',
        reminderMessage: 'Message',
        reminderMessages: {
            forgot_record: 'The total hours of the previous business day are less than {h} hours {mm} minutes. <br>You may have forgotten the record.',
            forgot_stop: 'You have a task that has been working for more than {h} hours {mm} minutes. <br>You may have forgotten to stop.',
        },
        reminderMinDuration: 'Minimum duration',
        reminderReceiver: 'Alert target',
        reminderReceivers: {
            all: 'All',
            member: 'Member',
            owner: 'Owner',
        },
        reminderSendTime: 'Send time',
        reminderTime: 'Time',
        reminderType: 'Reminder type',
        reminderTypes: {
            forgot_record: 'Previous business day',
            forgot_stop: 'Forgetting to stop',
            general: 'General Purpose',
        },
        reminderWorkingDuration: 'Working duration',
        remove: 'Remove',
        removed: 'Removed',
        renewInvitationUrl: 'Renew invitation URL',
        renewInvitationUrlDescription1: 'After renewing the invitation URL, the old invitation URL will no longer be available.',
        renewInvitationUrlDescription2: 'Renewing the invitation URL is effective when the invitation URL has expired or when the invitation URL has been known to an unintended person.',
        renewUrl: 'Renew URL',
        reportBookmarkCreated: 'Saved the bookmark',
        reportBookmarkDeleted: 'Removed the bookmark',
        reportBookmarkFavorited: 'Added to favorites',
        reportBookmarkReachedLimit: 'You have reached the limit of bookmarks. You can save up to 50 bookmarks.',
        reportBookmarkUnfavorited: 'Removed from favorites',
        reportBookmarkUpdated: 'Updated the bookmark',
        reportBookmarks: 'Bookmarks',
        reportMail: 'Report Mail',
        reportRecipientsSettings: 'To send the report email to other e-mail addresses, please add it to "Destination" at the link below.',
        resend: 'Resend',
        reset: 'Reset',
        restore: 'Restore',
        roles: {
            manager: 'Team Owner',
            member: 'Member',
            owner: 'Workspace Owner',
        },
        rounding: 'Rounding',
        roundings: {
            ceil: 'Round up',
            floor: 'Round down',
            round: 'Round off',
        },
        save: 'Save',
        saveThisCondition: 'Bookmark this condition',
        saved: 'Saved',
        saving: 'Saving',
        search: 'Search',
        searchByCategoryName: 'Search by category name',
        searchByCategoryOrTeamName: 'Search by category or team name',
        searchByTeamName: 'Search by team name',
        searchByUserName: 'Search by user name',
        searchResult: 'Search Result',
        seconds: 'Seconds',
        seeDetailedFeatureDescription: 'See detailed feature description',
        selectAccountAndIntegrateWithOutlook: 'Select Google Calendar or Outlook Calendar and start settings with the button below',
        selectAll: 'Select all',
        selectCalendarIntegrationSettingBaseType: 'Select base type',
        selectFile: 'select a file',
        selectFileSuffix: '',
        selectLeafs: 'Select all leafs',
        selectParents: 'Select all parents',
        selectPeriod: 'Select period',
        selectTeam: 'Select team',
        selected: 'Selected',
        selectingNUsers: 'Selecting %{count} users',
        sendConfirmation: 'Send confirmation instructions',
        setAutoStopTimeTracking: 'Set Auto stop time tracking',
        setNameFor: 'Please set the %{target} name',
        settings: 'Settings',
        shareURL: 'Share the URL below with the members you want to invite to your team.',
        showAllUsersReport: 'Show all users report',
        showBelow: 'Show below',
        showCategoryOnWeeklyCalendar: 'Show task category on weekly calendar',
        showCategoryOnWeeklyCalendarDescription: 'Show task category on weekly calendar',
        showLess: 'Show less',
        showMore: 'Show more',
        showMoreItems: 'Show more %{count} items',
        showRecentTasks: 'Show recent tasks',
        signInWithGoogle: 'Sign in with Google',
        signInWithMicrosoft: 'Sign in with Microsoft',
        startGeneration: 'Start generation',
        startMode: {
            auto: 'Do not start (The working task will not be stopped)',
            force: 'Force to start (The working task will be stopped)',
        },
        startModeLabel: 'When tracking at the scheduled start time',
        startMonth: 'Start month',
        startMonthHelpForLongTerm: 'You can change the start month of the fiscal year.',
        startMonthHelpForRealtime: 'You can change the quarter division by the start month of the fiscal year.',
        started_at: 'Started at',
        stop: 'Stop',
        stopMemberEntryModal: {
            description: 'Do you want to stop the time entry of %{user}?',
            stop: 'Stop',
            title: "Stop a member's working time entry",
        },
        stopMode: {
            auto: 'Force to stop (Stop automatically)',
            none: 'Do not stop (Stop manually)',
        },
        stopModeLabel: 'When the scheduled end time comes',
        stopped_at: 'Stopped at',
        stopping: 'Stopping',
        subscribePaidPlan: 'Subscribe to a paid plan',
        summary: 'Summary',
        targetCreated: '"%{target}" was successfully created.',
        targetDeleted: '"%{target}" was successfully deleted.',
        targetUpdated: '"%{target}" was successfully updated.',
        task: 'Task',
        taskCreated: 'Task "%{name}" was successfully created.',
        taskHistory: 'Task Histroy',
        taskInfo: 'Task Info',
        taskSettings: 'Edit a Task',
        tasksBulkCreated: 'Tasks were successfully created.',
        team: 'Team',
        teamCategory: 'Team/Category',
        teamEmploysDescription: 'These are the settings for each team member. Team settings take precedence over workspace settings.',
        teamEmploysTitle: 'Rate settings by teams',
        teamManagement: 'Team Management',
        teamMemberManagement: 'Team Member Management',
        teamName: 'Team name',
        teamNameExampleForPersonal: 'ex: Personal / My Project',
        teamOwnersInviteAllowedDescription: 'Workspace owners have the permission to invite new members to the workspace in team settings. <br>This options is for allowing team owners to have the same permission.',
        teamOwnersInviteAllowedLabel: 'Allow each team owner to invite new members to the workspace',
        teamOwnersInviteAllowedTitle: 'Team owner permissions',
        teamRole: 'Team role',
        teamSettings: 'Team Settings',
        teams: 'Teams',
        teamsAndCategories: 'Teams and Categories',
        thankYouRegister: 'Thank you for registering',
        thereAreBlankRequiredFields: 'There are blank required fields',
        thisFiscalYear: 'This fiscal year',
        thisIsForPaidTeams: 'This function is only for paid teams',
        thisMonth: 'This month',
        thisWeek: 'This week',
        timeEntry: 'Time Entry',
        timeEntryInProgress: 'In progress',
        timeZoneAndLocale: 'Time zone and locale',
        time_entry: 'TimeEntry',
        title: 'Title',
        toDate: 'to {{date}}',
        today: 'Today',
        totalAmount: 'Total amount',
        totalDuration: 'Total duration',
        totalWorkingTime: 'Total Working Time',
        trialEndsDate: 'Trial ends date',
        trialRemainingDays: '（%{days} days remaining）',
        twoFactorAuth: 'Two-factor authentication',
        unarchive: 'Unarchive',
        unconfirmedRecipient: 'This email address has not been confirmed. Click on the link in the confirmation email.',
        undo: 'Undo',
        unitMinutes: 'Time entry unit',
        unitMinutesDescription: 'Select the unit for entering time entries on the calendar.',
        unitPrice: 'Unit price',
        unpaid: 'Unpaid',
        unpaidAlerts: {
            reminderEmails: "<strong className='mr-1'>%{name}</strong> has been changed to the free plan and reminders have been disabled.<br>To continue using it, you need to switch to a paid plan.",
        },
        unrecoverableError: 'An unexpected error occurred and the link with the above account has been canceled. <br>Please try linking your account again.',
        unset: 'Unset',
        update: 'Update',
        updateCalendarIntegrationFilterSuccess: 'Filtering was successfully updated.',
        updateCalendarIntegrationSettingSuccess: 'Calendar integration settings was successfully updated.',
        updateReportBookmarkHeader: 'Bookmark details',
        updateReportBookmarkTitleLabel: 'Edit the name',
        url: 'URL',
        user: 'User',
        userId: 'User ID',
        username: 'Username',
        users: 'Users',
        walkthroughPlanButtonDescription: 'All features available',
        walkthroughPlanButtonLabel: 'Free trial',
        walkthroughPlanLinkLabel1: 'Contact for about features',
        walkthroughPlanTitle: 'Use for organization',
        walkthroughWelcomeNotice1: 'For users who accessed from the invitation URL',
        walkthroughWelcomeNotice2: 'If you have received an invitation, please join TimeCrowd again from the invitation URL instead of starting here.',
        weekOfMonthFormat: 'MMM [W]%{week}',
        weekly: 'Weekly',
        willDeleteTeams: 'Teams to be deleted',
        workspace: 'Workspace',
        workspaceCalendarIntegrationSettingDescription1: 'All members of a workspace can use the common calendar synchronization settings.',
        workspaceCalendarIntegrationSettingDescription2: 'However, members who wish to use this feature must set up calendar synchronization settings in their own personal settings. Click here for your own calendar settings.',
        workspaceDefaultBlankValue: 'Please select a workspace',
        workspaceId: 'Workspace ID',
        workspaceInvitationDescription1: 'When inviting members with no team, please use this URL.',
        workspaceInvitationDescription2: 'This URL can be used to invite multiple people.',
        workspaceInvitationDescription3: 'Please be careful not to let people other than members know this URL.',
        workspaceInvitationDescription4: 'Invitation for members with a team is available here',
        workspaceInvitationUrl: 'Workspace invitation URL',
        workspaceName: 'Workspace Name',
        workspacePersonalNote1: 'You can only create one team with your personal plan.',
        workspacePersonalNote2: 'If you would like to create multiple teams or add members other than yourself, please',
        workspacePersonalNote3: 'us',
        workspacePlanNotes: {
            general: 'Please contact your sales representative if you wish to change your plan due to an increase or decrease in the number of users.',
            personal: 'If you are considering using it as a team, we can explain the features and offer a trial.<br>Please feel free to contact us.',
            trial: 'We are always available to discuss your contract and extension of the trial period.<br>Please feel free to contact our sales department.',
        },
        workspacePlans: {
            basic: 'Basic',
            business: 'Business',
            enterprise_trial: 'Enterprise - Trial',
            legacy_enterprise: 'Enterprise',
            legacy_light: 'Light',
            legacy_standard: 'Standard',
            minimum: 'Minimum',
            personal: 'Personal',
            starter: 'Starter',
        },
        workspaceRole: 'Workspace role',
        yesterday: 'Yesterday',
        you: 'You',
        yourTime: 'Your time',
    },
    taskEdit: {
        addTimeEntry: 'New Time Entry',
        editTask: 'Edit task',
        taskName: 'Task name',
    },
    taskManagement: {
        all: 'All',
        allTeams: 'All Teams',
        allUsers: 'All Users',
        blankDescription: "Let's make it",
        blankDescriptionClosed: "Let's complete a task",
        blankTitle: 'No tasks',
        blankTitleClosed: 'No closed tasks',
        blankTitleOpen: 'No open tasks',
        blankTitleTrashed: 'No trashed tasks',
        closed: 'Closed',
        deleteTasksAfterTwoWeeks: 'Trashed tasks are automatically deleted in two weeks.',
        open: 'Open',
        taskTitle: 'Task title',
        trashed: 'Trashed',
    },
    taskMergeModal: {
        attention: 'Move the all time entries to the task with the same name in the same category. Please note that it will be moved even if there are time entries other than yourself.',
        desc: 'A task with the same name exists in the same category. Move time entries to the task to be changed, is it OK?',
        submit: 'Move',
        title: 'Merge the task',
    },
    tasklist: {
        all: 'All',
        category: 'Category',
        delete: 'Delete a task',
        done: 'Done',
        edit: 'Edit',
        history: 'History',
        link: 'Jump to task URL',
        pin: 'Pin',
        start: 'Start',
        status: 'Status',
        stop: 'Stop',
        view: 'View',
        yet: 'Yet',
    },
    teamChildren: {
        attention: 'Are you sure "%{childTeamName}" leave from "%{parentTeamName}"?',
        notExistChildren: 'Child team does not exist',
    },
    teamManagement: {
        addEmploy: 'Set hourly rate',
        addScheduledEmploy: 'Add schedule to change hourly rate',
        addTeam: 'Add New Team',
        addvancedSettings: 'Advanced Settings',
        avatar: 'Avatar',
        billingForm: {
            accept: 'We accepted upgrading your plan.',
            amount: 'Aount',
            annotationForInvoicePayment: {
                afterText: 'without entering the card information.',
                beforeText: 'If you want to pay with an invoice, contact us from',
                helpText: 'here',
            },
            annotationForPlanApplication: 'This is for the light plan',
            apply: 'Apply',
            change: 'Change to Paid Plan',
            complete: 'Complete payment',
            date: 'Date',
            free: 'Free',
            noNeed: 'This team is set as a premium. Payment is not required.',
            paid: 'Paid',
            paymentInfo: 'Payment Info',
            receipt: 'Receipt',
            report: 'With a paid plan, you can view the report of the team.',
            status: 'Status',
            thankYou: 'Thank you for your payment.',
            title: 'Plan',
            unpaid: 'Unpaid',
        },
        category: 'Category',
        categoryMenu: {
            archiveCategory: 'Archive the category',
            copyCategory: 'Copy the category',
            createChildCategory: 'Create a child category under this category',
            moveHigherCategoryPosition: 'Move the category to higher position',
            moveLowerCategoryPosition: 'Move the category to lower position',
            unarchiveCategory: 'Unarchive the category',
        },
        categoryPage: {
            add: 'Add Category',
            categoryTitle: 'Title',
            info: 'Category Info',
            member: 'Member',
            noticeForCopy: 'Create a new category by copying the child categories and tasks of the category "%{category}". <br>* Time entries will not be copied',
            parentCategory: 'Parent Category',
            selectColor: 'Color',
            title: 'Category Settings',
            toBeCopied: 'Categories and tasks to be copied',
        },
        deleteMembership: 'Delete membership',
        destroyTeam: {
            attention: 'The following teams, their categories, tasks, and time entries will all be deleted. (Includes archived categories)',
            attention_children: 'The following teams will also be deleted.',
            attention_payment_page: 'the help page',
            attention_payment_prefix: 'Please check ',
            attention_payment_suffix: ' for details to cancel paid plan of the team.',
            confirmation: 'I have confirmed that the above team, its categories, tasks, and time entries will all be deleted.',
            delete: 'Delete a Team',
            noticePaidTeam1: 'Paid plan of the %{name} is ongoing.',
            noticePaidTeam2: "I'm sorry to trouble you to delete the %{target}, but please cancel a paid plan. After canceling the paid plan, you can delete the %{target}.",
            noticeParent: '%{name} has child teams and cannot be deleted.',
            noticePersonal: '%{name} is a personal team and cannot be deleted.',
        },
        editTeam: {
            title: 'Edit a Team',
        },
        employ: 'Hourly Rate',
        integration: 'Integration',
        invitation: {
            byUrl: {
                attention: 'People who received this invitation URL will be able to view the tasks of this %{target}. Please be careful not to let other people know this URL.',
                expiresAt: 'Expires At',
                pleaseRenew: 'Invitation is expired. Please renew to use.',
                renew: 'Renew URL',
                renewed: 'Renewed URL',
                title: 'Invitation URL',
            },
        },
        legacy: 'Legacy Settings',
        member: 'Member',
        memberTab: {
            editPermissions: 'Edit permissions',
            rate: 'Rate',
            title: 'Manage Members',
        },
        membershipRoleInput: {
            updateSuccess: 'The role of {{nickname}} has become {{role}}',
        },
        newTeam: {
            defaultName: 'New Team',
        },
        payByCreditCard: 'Pay by credit card',
        payWithInvoice: 'Pay with invoice',
        paymentHint1: 'You can try it free for 2 weeks.',
        paymentHint2: 'After 2 weeks, some functions can not be used.',
        paymentHint3: 'In order to continue to use all functions you need to charge.',
        paymentHintLabel: 'Getting Started',
        paymentManualHint: 'Please refer to the man page below for the charge.',
        permissionTab: {
            add: 'Add',
        },
        personalTeamAlert: {
            afterText: ' for details.',
            beforeText: 'This team is for personal use and you cannot invite other members. Create a new team for collaboration. Please see the ',
            helpText: 'help',
        },
        plan: 'Plan',
        removeCategoryModal: {
            attention: 'The following categories and all tasks and time entries belonging to this category are deleted. (The archived categories will be included)',
            confirmation: 'I confirmed that all the above categories and tasks and time entries belonging to this category will be deleted.',
            title: 'Delete a category',
        },
        removeFromTeam: 'Remove from team',
        removeUserFromTarget: 'Delete membership of the user from %{target}',
        removeUserFromTargetConfirm: 'Are you sure you want to remove the following user from %{target} members?',
        roundingHint1: 'Amount of each time entry is calculated from the following formula:',
        roundingHint2: 'hourly rate × duration ÷ 3600',
        roundingHint3: 'The amount will be rounded according to this setting.',
        settings: 'Team Settings',
        teamId: 'Team ID',
        teamName: 'Team Name',
        timeLimit: 'Time limit (Minutes)',
        timeLimitHint1: 'Time limit for duration of time_entry.',
        timeLimitHint2: 'No limit if time limit is 0.',
        webHook: 'WebHook',
    },
    timeEntryMergeModal: {
        attention: 'Move the all time entries to the task with the same name in the change destination category. Please note that it will be moved even if there are time entries other than yourself.',
        desc: 'A task with the same name exists in the destination category. Move time entries to the task to be changed, is it OK?',
        submit: 'Move',
        title: 'Change category of the task',
    },
    timeInput: {
        error: {
            format: 'Please enter the time in hh:mm format',
            inclusion: 'Please enter the time in the range 00:00~23:59.',
        },
    },
    timespan: {
        hasOverwrapTimeEntry: 'It overlaps with the following time entries.',
    },
    webHook: {
        create: {
            title: 'Create a WebHook',
        },
        eventEntryCreate: 'Create entry',
        eventEntryDelete: 'Delete entry',
        eventEntryEdit: 'Edit entry',
        eventEntryStart: 'Start entry',
        eventEntryStop: 'Stop entry',
        events: 'Events',
        listTitle: 'WebHook List',
        secret: 'Secret',
        secretHelp: 'Used to verify the request. Use the secret as a key to get the HMAC-SHA256 digest value of the request body. Make sure that the Base64 of the digest and the TimeCrowd-Signature header match.',
        url: 'WebHook URL',
    },
    welcome: {
        currentLoginHtml: 'Logging in with <b>%{email}</b>',
        logoutOrDeactivate: 'Sign out or deactivate an account',
        logoutOrDeactivateDescriptionHtml: 'If you are a past user or have registered using an incorrect login method, please click below.',
    },
    workingusers: {
        edit: 'Edit',
        start: 'Start',
        stop: 'Stop',
    },
};
