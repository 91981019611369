import React from 'react';
import classnames from 'classnames';
import { imageSource } from '@/utils/image_helper';
var Blank = function (props) {
    var _a;
    var className = props.className, _b = props.tag, Tag = _b === void 0 ? 'div' : _b, children = props.children, title = props.title, description = props.description, _c = props.color, color = _c === void 0 ? 'normal' : _c, _d = props.fixed, fixed = _d === void 0 ? false : _d;
    var classes = classnames('blank bg-blank', className, (_a = {},
        _a['blank-' + color] = true,
        _a.fixed = fixed,
        _a));
    return (<Tag className={classes}>
      <div className="blank-container">
        <div className="blank-header">
          <img alt={title} className="blank-image" src={imageSource('tc-logo-color.png')} title={title}/>
          <p className="lead blank-title mb-1">{title}</p>
          {description && <p className="blank-description">{description}</p>}
        </div>
        <div className="blank-body">{children}</div>
      </div>
    </Tag>);
};
export default Blank;
