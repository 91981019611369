import { RAILS_ENV } from '@/constants/rails_env';
import variants from '@/constants/variants';
var apiBase = variants.apiBase;
export var imageHost = function (railsEnv) {
    return railsEnv === 'development'
        ? apiBase
        : "https://".concat(railsEnv || 'staging', "-assets-timecrowd.s3-ap-northeast-1.amazonaws.com");
};
export function imageSource(path) {
    var host = imageHost(RAILS_ENV);
    return "".concat(host, "/assets/react/v1/").concat(path);
}
