/* eslint sort-keys: error */
import dateFnsLocale from './date-fns/locale/ja';
export default {
    actions: {
        change: '変更する',
    },
    calendar: {
        addToTimelineWithTime: '{{time}}に追加',
        osTimeZone: 'ご利用環境のタイムゾーン',
        timeCrowdTimeZone: 'TimeCrowdのタイムゾーン',
        timeEntryUpdated: '作業履歴は正常に更新されました。',
        timeZoneDifference: 'TimeCrowdのタイムゾーンとご利用環境のタイムゾーンが異なります。カレンダー表示などに不具合が発生する可能性があるため、ご利用環境のタイムゾーンに合わせて、TimeCrowdのタイムゾーンを変更してください。',
    },
    calendarIntegrationWorkspaceSetting: {
        allTeamsOwnerAuthorization: '全てのチームのオーナー権限',
        authorizationDescription: 'この機能を設定するには以下の全ての権限を持つユーザーに限られています。',
        currentPrivilegedUsers: '現在の権限保持者',
        descriptionHtml: '予定のタイトルにキーワードが含まれていた場合、指定したカテゴリーの打刻として保存します。<br>ワークスペース内の条件には、すべて異なるキーワードを設定してください。',
        label: 'チームメンバー全員へのカテゴリーの自動振り分け設定',
        teamFilterLabel: '表示中の振り分け条件',
        workspaceAuthorization: 'ワークスペースオーナー権限',
    },
    card: {
        modal: {
            body: '<p>クレジットカードの変更が正常に完了しました。</p>',
            header: 'クレジットカード変更を受け付けました',
        },
    },
    confirmLeaveTeamMyselfModal: {
        confirmMessage: 'あなたをチームから外します。本当によろしいですか？',
        descriptionFooter: '再度チームの情報を閲覧するためには、他のチームオーナーからの招待が必要になります。',
        descriptionHeader: 'チームを外れると以下のチームに関する操作が全てできなくなります。',
        leaveTeam: '自分をチームから外す',
        leaveTeamSuccess: '%{teamName} を退出しました。',
        managerDescription1: 'チームメンバーの招待や確認',
        managerDescription2: 'あなたを含めたチームのタスクの閲覧や編集',
        managerDescription3: 'あなたを含めたチームのレポートの閲覧',
        memberDescription1: 'チームのタスクの閲覧や編集',
        memberDescription2: 'あなたのレポートの閲覧',
        memberDescription3: 'チームメンバーの確認',
    },
    csv: {
        categoryId: 'カテゴリーID',
        categoryTitle: 'カテゴリー名',
        dateTimeFormat: 'YYYY/MM/DD HH:mm:ss',
        hms: '%{h}時間 %{m}分 %{s}秒',
        taskAmount: '合計金額（円）',
        taskId: 'タスクID',
        taskState: 'ステータス',
        taskTitle: 'タスク名',
        taskTotalTime: '合計作業時間',
        taskUrl: 'タスクURL',
        teamId: 'チームID',
        teamName: 'チーム名',
        timeEntryAmount: '金額（円）',
        timeEntryComment: 'コメント',
        timeEntryDuration: '作業時間',
        timeEntryId: '作業履歴ID',
        timeEntryStartedAt: '開始時間',
        timeEntryStoppedAt: '停止時間',
        userId: 'ユーザーID',
        userNickname: 'ユーザー名',
    },
    deleteTaskModal: {
        additional: '（削除すると「タスク管理」の「削除済み」ステータスに保管されますが、2週間経過すると完全に削除されます。）',
        attention: '他のメンバーも同じタスクで記録をした場合、自分の作業履歴だけでなく他のメンバーの作業履歴も削除されます。',
        confirmation: '以下のタスクを削除しますが、よろしいでしょうか？',
        title: 'タスクを削除する',
    },
    deleteTimeEntryModal: {
        attention: '作業履歴は削除すると元に戻せません。',
        confirmation: '以下の作業履歴を削除しますが、よろしいでしょうか？',
        title: '作業履歴を削除する',
    },
    duration: {
        hours: '時間',
        minutes: '分',
        seconds: '秒',
    },
    errors: {
        contactUs: 'お問い合わせについては、下記の連絡方法のいずれかをお選びいただきご連絡ください。',
        description: {
            403: 'このページにアクセスする権限がありません。',
            404: 'お探しのページを見つけることができません。',
            500: 'エラーが発生しました。時間をおいて再度お試しください。',
        },
        failedFetchPeriods: '期間情報の取得に失敗しました。ブラウザをリロードしてください。',
        forbidden: '該当操作の権限がありません。',
        internalServerError: 'エラーが発生しました。時間を置いてから再度アクセスしてください。<br>解決しない場合は「お問い合わせ」よりご連絡ください。',
        network: '通信中にエラーが発生しました。もう一度操作してください。',
        notFound: '対象のデータが見つかりません。ページを更新してから再度操作してください。',
        title: {
            403: '権限がありません',
            404: 'ページが見つかりません',
            500: '予期せぬエラーが発生しました',
        },
        tooLarge: 'データサイズが1MBを超えています。',
    },
    export: {
        allPeriods: '全期間',
        allTeams: 'すべてのチーム',
        allUsers: 'すべてのユーザー',
        blankDescription: '',
        blankTitle: 'CSVデータがありません',
        by: {
            task: 'タスク単位',
            time_entry: '作業履歴単位',
        },
        charset: {
            label: '文字コード',
            sjis: 'Shift_JIS',
            utf8: 'UTF-8',
            utf8_bom: 'UTF-8（BOM付き）',
        },
        createdAt: '作成日',
        dataType: 'データ種別',
        days: '日',
        deleteCsv: {
            attention: '選択されたCSVデータを完全に削除します。',
            header: 'CSVを削除',
        },
        example: '例：',
        examples: {
            '6min': '1.1',
            hms: '1時02分01秒',
            hms_colon: '1:02:01',
            seconds: '3721',
        },
        fileType: 'ファイルタイプ',
        format: {
            '6min': '6分刻みで0.1（切り上げ）',
            hms: '時間・分・秒（日本語区切り）',
            hms_colon: '時間・分・秒（コロン区切り）',
            seconds: '秒',
        },
        generate: '生成',
        generateNewData: 'データ生成',
        lastMonth: '先月',
        lastWeek: '先週',
        lastYear: '去年',
        multipleTeams: '複数チーム',
        multipleUsers: '複数ユーザー',
        noTeams: 'チーム未選択',
        noUsers: 'ユーザー未選択',
        notice: {
            '6min': '※ 端数（秒・分）は切り上げて出力するため、「タスク単位」と「作業履歴単位」で集計した数値に差が出る場合があります',
            hms: '',
            hms_colon: '',
            seconds: '',
        },
        notify: {
            cancel: 'CSVエクスポートをキャンセルしました。',
            failure: 'CSVエクスポートに失敗しました。',
            in_progress: 'CSVエクスポートを開始しました。',
            initial: 'CSVエクスポートを開始しました。',
            success: 'CSVエクスポートが正常に終了しました。',
        },
        range: '期間',
        target: '対象',
        thisMonth: '今月',
        thisWeek: '今週',
        thisYear: '今年',
        timeFormat: '時刻形式',
        to: 'から',
        today: '今日',
        trialDuring: 'あと%{period}日で<strong className="mr-1">%{name}</strong>の2週間の無料トライアル期間が終了になります。終了すると、データのエクスポートが出来なくなります。',
        trialEndedToMember: '<strong className="mr-1">%{name}</strong>の2週間の無料トライアル期間が終了しました。エクスポート機能を制限しています。',
        trialEndedToOwner: '<strong className="mr-1">%{name}</strong>の2週間の無料トライアル期間が終了しました。<br />引き続きデータのエクスポートを利用するには、有料プランへの移行が必要です。',
        yesterday: '昨日',
    },
    hardDeleteTaskModal: {
        confirmation: '上記について了解しました',
        description: '以下のタスクを完全に削除しますが、よろしいでしょうか？（完全に削除されたタスクは復元することができません。）',
        title: 'タスクを完全に削除する',
    },
    infiniteCalendar: {
        blank: '日付を選択...',
        headerFormat: 'MM-DD(ddd)',
        locale: dateFnsLocale,
        todayLabel: {
            long: '今日',
        },
        weekStartsOn: 0,
        weekdays: ['日', '月', '火', '水', '木', '金', '土'],
    },
    integration: {
        chatwork: 'チャット名をカテゴリーとして自動作成する（Chrome拡張）',
        notFoundIntegrations: '連携可能な外部サービスが見つかりませんでした。',
    },
    lang: 'jp',
    mainmenu: {
        members: 'メンバー',
        more: 'その他',
        mytask: 'マイタスク',
        report: 'レポート',
        workspaceSettings: 'ワークスペース設定',
    },
    member: {
        blankDescription: '',
        blankTitle: '稼働中のメンバーがいません',
        nonWorking: '非稼動',
        realtime: 'リアルタイム',
        timeline: '今日の稼働',
    },
    members: {
        addCapacity: '最大人数を追加',
        memberCountLabel: '所属メンバー',
        memberCountUnit: '人',
        seatLeft: 'あと%{count}人追加可能',
    },
    model: {
        attributes: {
            team: {
                defaultDuration: 'デフォルト作業時間',
                name: 'チーム名',
            },
        },
    },
    mydatebar: {
        today: '今日',
    },
    mytask: {
        blankDescriptipn: 'タスクを追加してみましょう',
        blankTitle: 'タスクがありません',
    },
    other: {
        changePassword: 'パスワード変更',
        dataExport: 'エクスポート',
        personalSettings: '個人設定',
        reportRecipients: 'レポート送信先',
        signOut: 'ログアウト',
        taskManagement: 'タスク管理',
    },
    payment: {
        applyCreditCard: 'クレジットカード決済',
        changeLater: '人数はあとからでも変更可能です',
        changeMaximumNumber: '変更',
        chromeExtension: {
            description: 'Chrome拡張機能から課金処理を実行することはできません。下記のリンクよりPCサイトに移動して課金処理を行ってください。',
            goToPC: 'PCサイトへ移動',
        },
        creditCardSettings: 'クレジットカード設定',
        currently: '現在',
        explainPaymentDeadline: 'このチームは毎月{{date}}日に支払いが請求されます。',
        history: '履歴',
        maximum: '最大',
        meteredRate: '利用人数に応じて従量制で支払う',
        numberOfPeople: '人数',
        payjp: {
            submitText: '保存する',
            text: '利用するクレジットカードを変更する',
        },
        paymentMethods: 'お支払い設定',
        people: '人',
        setUpperLimit: '上限を設定する',
        taxIncluded: '税込',
        totalAmount: '支払い金額',
        trialRemaining: 'お試し期間残り：{{days}}日',
        upToPeople: '人まで',
    },
    personalSetting: {
        appConnectGoogleAccountNotice: 'アプリからGoogleアカウント連携を実行することはできません。ブラウザを利用してGoogleアカウント連携を行ってください。',
        backupCodeAlreadyDownloaded: '既にダウンロード済みです',
        backupCodeDesc: 'ワンタイムパスワードの代わりに使えるコードで、ダウンロードできるのは一度だけです。各コードはログインに使ったら無効化されます。',
        connectAppleAccounts: '他のAppleアカウントを追加する',
        connectGoogleAccounts: '他のGoogleアカウントを追加する',
        connectOutlookAccounts: '他のMicrosoftアカウントを追加する',
        connectSamlAccounts: '他のSAMLアカウントを追加する',
        contacts: '連絡先',
        crxConnectGoogleAccountNotice: 'Chrome拡張機能からGoogleアカウント連携を実行することはできません。下記のリンクよりPCサイトに移動してGoogleアカウント連携を行ってください。',
        deactivate: '退会する',
        deactivation: {
            attention: 'あなたが退会した後、データを以下のように扱います。',
            attentionDetail1: 'あなたの名前以外の個人情報は、TimeCrowd内で個人が特定できないよう改変されます。',
            attentionDetail2: '基本的に、作業履歴は退会済みユーザーの記録として残ります。',
            attentionDetail3: 'ただし、個人チームの作業履歴は全データが削除されます。',
            checkHelpPage: '詳しくはヘルプページをご確認ください。',
            confirmation: '上記について了解しました',
            yourEmailHtml: '退会するアカウントのメールアドレス: <b>%{email}</b>',
        },
        destination: '送信先',
        goToPC: 'PCサイトへ移動',
        otpDesc: '二要素認証アプリで以下のQRコードを読み取った後、表示されるワンタイムパスワードを入力し、保存ボタンを押してください。',
        receiveDailyReport: '日次レポートを受け取る',
        receiveWeeklyReport: '週次レポートを受け取る',
        receiveWeeklyReportDesc: '毎週月曜日の19時に前週の月〜日曜日レポートが送られます',
        reportMail: 'レポートメール',
        requiredTwoFactorAuth: '有効化',
        requiredTwoFactorAuthDesc: 'ログインする際に二要素認証が必須になります',
        requiredTwoFactorAuthLabel: '二要素認証を有効化します',
        sendSettings: '送信設定',
        timeOfReceivingDailyReport: '日次レポートの送信時間',
        timeOfReceivingDailyReportDesc: '設定した時間に直近24時間の作業レポートが送られます',
        twoFactorAuth: '二要素認証',
    },
    proxyInputUser: {
        label: 'チームオーナーが追加した作業履歴',
        message: '%{nickname}さんの作業履歴の追加を代理で入力しています',
        notification: '%{nickname}さんの作業履歴を代理で追加しました。',
    },
    quicktask: {
        appointment: '日程予約',
        category: 'カテゴリー',
        comment: 'コメント',
        freeTime: '空き時間',
        fromPage: '閲覧サイトから',
        insertTaskTitle: 'タスクのタイトルを入力',
        newtask: 'タスクの追加と開始',
        start: '開始',
        stop: '停止',
        task: 'タスク',
        team: 'チーム',
        withoutTimeEntry: '時間指定なし',
    },
    report: {
        allCategory: '全てのカテゴリー',
        blankDescription: 'レポートの操作方法は<a href="https://help.timecrowd.net/report" target="_blank">こちら</a>',
        blankDescriptionForPaymentMember: '有料プランへの切り替えはチームオーナーのみが行えます',
        blankDescriptionForPaymentPrefix: '有料プランへの切り替えは',
        blankDescriptionForPaymentSuffix: '',
        blankTitle: 'レポートデータがありません',
        blankTitleForPayment: 'トライアル期間が終了したためレポートが表示できません',
        byCategory: 'カテゴリー別',
        byUser: 'ユーザー別',
        csv: {
            entries: 'CSV（作業ログ）',
            task: 'CSV（タスク）',
        },
        details: '詳細',
        download: 'ダウンロード',
        download_csv: 'CSVダウンロード',
        duration: '作業時間',
        hideInactiveMembers: '未稼働のメンバーを非表示',
        hours: '時間',
        orderedByCategories: 'カテゴリー順',
        orderedByTotalTime: '時間順',
        period: '期間',
        ratio: 'カテゴリー割合',
        series: '稼働時間',
        showInactiveMembers: '未稼働のメンバーを表示',
        team: 'チーム',
        trialDuring: 'あと%{period}日で<strong className="mr-1">%{name}</strong>の2週間の無料トライアル期間が終了になります。終了すると、レポートが表示出来なくなります。',
        trialEndedToMember: '<strong className="mr-1">%{name}</strong>の2週間の無料トライアル期間が終了しました。レポートの表示を制限しています。',
        trialEndedToOwner: '<strong className="mr-1">%{name}</strong>の2週間の無料トライアル期間が終了しました。<br />引き続きレポートを確認するには、有料プランへの移行が必要です。',
        user: 'ユーザー',
        you: 'あなた',
    },
    reportRecipients: {
        notFound: '登録されている追加のレポート送信先はありません。',
        registered: '登録済みレポート送信先',
        title: 'レポート送信先',
    },
    shared: {
        aboutPlans: 'プランの違いについて',
        account: 'アカウント',
        add: '追加',
        addChildTeam: '子チームを追加',
        addFiltering: '条件を追加',
        addReminder: 'アラートの追加',
        addReminderIgnore: '送信休止日を追加する',
        addTask: '新しいタスク',
        addTeamEmploy: 'このチームでの単価を設定する',
        addTimeEntry: '作業履歴の追加',
        addWorkspaceEmploy: '単価設定を追加する',
        all: 'すべて',
        allBelongTeams: '全ての所属チーム',
        allCategories: '全てのカテゴリー',
        allPeriods: '全期間',
        allTeams: '全チーム',
        allUsers: 'すべてのユーザー',
        amount: '金額',
        apply: '適用',
        archive: 'アーカイブ',
        archiveCategoryModalDescription: {
            archive: '下記のカテゴリーが全てアーカイブされます。',
            unarchive: '下記のカテゴリーが全てアーカイブ解除されます。',
        },
        archiveCategoryModalTitle: {
            archive: 'カテゴリーのアーカイブ',
            unarchive: 'カテゴリーのアーカイブ解除',
        },
        archived: 'アーカイブ済み',
        archivedTaskCannotBeEdited: 'アーカイブ済みカテゴリーのタスクは編集できません',
        archivedTaskCannotBeStarted: 'アーカイブ済みカテゴリーのタスクは開始できません',
        asOf: '%{time}時点',
        autoStart: '自動開始',
        autoStartLabel: 'カレンダーの予定で自動開始・停止する',
        autoStopTimeTracking: '打刻の自動停止',
        avatarHelp: 'JPG/PNG/GIF形式、1MB以下',
        back: '戻る',
        backupCode: 'バックアップコード',
        beta: 'β版',
        between: 'から',
        bouncedRecipient: 'レポートメールを送信できませんでした。アドレスを確認してください。',
        bulkCreateCategoriesAndTasks: 'カテゴリー・タスク一括追加',
        bulkCreateWithLikeBreak: '改行すると複数のタスクを一括で作成できます（最大100件まで）',
        businessDay: '営業日',
        calculation: '計算',
        calendar: 'カレンダー',
        calendarIntegrationSelectWorkspace: '振り分け設定を適用するワークスペースを選択',
        calendarIntegrationSettingsBaseTypeDescriptionHtml: 'カレンダーの同期設定を自分で決めるか、ある一つのワークスペースで決められたものにならうかどれか一つを選択できます。<br />複数の設定を一度に適用することはできません。',
        calendarIntegrationSettingsBaseTypePersonal: '自分の設定',
        calendarIntegrationSettingsBaseTypeWorkspace: 'ワークスペースの設定',
        calendarIntegrationSettingsPriorityDescription1: '優先順位とは、カレンダーの予定に部分的に同じキーワードが含まれている場合、どの振り分けルールを優先して適用するかを決めるものです。',
        calendarIntegrationSettingsPriorityDescription2: '優先順位はチーム単位ではなく、ワークスペース全体で適用されますので、複数チームで条件を作成している場合優先順位が必ずしも連番にはなりません。',
        calendarIntegrations: '外部カレンダー同期',
        calendarSettings: 'カレンダー設定',
        calendarViews: {
            daily: '日',
            weekly: '週',
        },
        calendarZoomRate: 'カレンダー表示倍率',
        canChangeLater: 'あとから変更することも可能です。',
        canDoLater: 'あとで行うことも可能です。',
        cancel: 'キャンセル',
        cancelIntegration: '同期の停止',
        canceled: 'キャンセル済み',
        cannotBeStopped: 'この操作は停止出来ませんのでご注意ください',
        cannotReorderWhileSearching: '現在絞り込み状態の状態のため順番を変更できません',
        cannotShowMenuForTeam: '%{team}の%{menu}にアクセスする権限がありません。メンバー画面を表示します。',
        capacityModalBody: '有料プランの人数上限設定が変更されました',
        capacityModalTitle: '上限設定の変更',
        category: 'カテゴリー',
        categoryListSearchPlaceholder: 'カテゴリー名',
        categoryManagement: 'カテゴリー管理',
        categorySettings: 'カテゴリー設定',
        categoryTaskStructureExportHistory: 'CSVファイルエクスポート履歴',
        categoryTaskStructureExportNote: '現在登録されているカテゴリーとタスクをCSVファイルでエクスポートします',
        categoryTaskStructureImportHistory: 'CSVファイルインポート履歴',
        categoryTaskStructureImportNote1: 'CSVファイルをインポートすることでカテゴリーとタスクを一括で追加することができます',
        categoryTaskStructureImportNote2: 'CSVファイルのひな形は',
        caution: '注意',
        change: '変更',
        changeEmail: 'メールアドレスの変更',
        changeEmailDescription: '新しいメールアドレスにご本人様確認メールを送信します。',
        changeLinkedAccount: '別のアカウントに変更',
        changeTask: 'タスク変更',
        chatwork: 'ChatWork',
        clear: 'クリア',
        clickHereForDetails: '詳しくはこちら',
        clickHereToPay: '有料プランへのお申込みはこちら',
        close: '閉じる',
        companyName: '会社名/団体名',
        completed: '完了済',
        confirm: '確認',
        confirmation: '上記について了解しました',
        confirmationEmailSent: '確認メールを送信しました。メール内のリンクをクリックしてください。',
        contactForExtendTrial: 'トライアル期間延長のお問い合わせ',
        contactSupport: 'お問い合わせ',
        contactViaChatwork: 'チャットワークでお問い合わせ',
        contactViaEmail: 'メールでお問い合わせ',
        copied: 'コピー済み',
        copy: 'コピー',
        copyOf: '%{title}のコピー',
        countOfFilters: '%{count} 件',
        create: '作成',
        createA: '%{name}を作成',
        createCalendarIntegrationFilterSuccess: '条件を追加しました。',
        createChildTeam: '子チームを作成',
        createOrJoinTo: '新しく%{target}を作るか、招待リンクから%{target}に参加してください。',
        createPayment: 'お申し込みを完了する',
        createPaymentDescription: 'まだお申込みは完了していません',
        createReportBookmarkDescription: 'こちらの条件を保存します。保存した条件は他のメンバーに見られることはありません。',
        createReportBookmarkHeader: '条件を保存する',
        createReportBookmarkTitleLabel: 'この条件のタイトルを入力（任意）',
        createReportBookmarkTitlePlaceholder: '〇〇さんの今月の業務、プロジェクトA・Bの今年の人件費',
        createTeam: 'チーム作成',
        createdWithName: 'こちらの名前で%{target}が作成されます。',
        crossTeamReport: 'チーム横断レポート',
        crossTeamReportLoadingDescription: '画面はそのままでお待ちください',
        crossTeamReportLoadingTitleForLongTerm: '長期レポート表示準備中',
        crossTeamReportLoadingTitleForRealtime: 'リアルタイムレポート作成中',
        csv: 'CSV',
        currencyUnit: '¥',
        currentEmail: '現在のメールアドレス',
        currentPlan: '契約中プラン',
        currentQuarter: '今四半期',
        currentRate: '現在の単価',
        customFieldDetails: 'カスタムフィールド情報',
        customFieldList: 'カスタムフィールド一覧',
        customFields: 'カスタムフィールド',
        customFieldsDescription1: 'カテゴリーに紐付けるカスタムフィールドを設定します。',
        customFieldsDescription2: 'カスタムフィールド名はユニークに設定してください。',
        customFieldsExpression: '式',
        customFieldsExpressionCalculating: '計算中',
        customFieldsExpressionErrors: {
            master_not_found: '参照されているカスタムフィールドが存在しません',
            unexpected_error: '予期せぬエラーが発生しました',
            value_not_found: '参照したカスタムフィールドに未入力項目があるため演算に失敗しました',
            zero_division_error: '0で割ることはできません',
        },
        customFieldsExpressionLabels: {
            customField: 'カスタムフィールド',
            totalAmount: '稼働金額',
            totalDuration: '打刻時間（秒）',
        },
        customFieldsExpressionPlaceholder: '演算式を入力してください',
        customFieldsName: 'カスタムフィールド名',
        customFieldsNotAllowed1: 'こちらの機能はスタンダードプラン、エンタープライズプランの機能となっております。',
        customFieldsNotAllowed2: '社内の管理目的で、案件コードを設定する',
        customFieldsNotAllowed3: 'カテゴリーごとの見積もり工数を登録し、予実管理を実施する',
        customFieldsNotAllowed4: '上記の活用方法についてご興味がある方は、弊社担当者からのご説明も可能です。お気軽にお問い合わせください。',
        customFieldsReachedLimit1: 'カスタムフィールドの設定上限数に達しました。',
        customFieldsReachedLimit2: '設定数の追加をご希望のお客様はお問い合わせください。',
        customFieldsRequirementAll: '入力必須にする',
        customFieldsRequirementLeaf: '末端カテゴリーのみ必須とする',
        customFieldsType: '種類',
        customFieldsTypes: {
            duration: '時間',
            expression: '演算',
            number: '数値',
            text: 'テキスト',
        },
        customFieldsUnique: '同じ値の入力を禁止する',
        customFieldsUnit: '単位',
        customFieldsValuesDuplicated: '既に重複している入力値が存在します。入力値を編集してから再度お試しください。',
        customFieldsValuesInvalid: '以下のカスタムフィールドが不正です',
        customFieldsVisibilities: {
            all: '全員に表示',
            owner: 'オーナーのみ表示',
        },
        customFieldsVisibility: '表示設定',
        daily: '日次',
        date: '日付',
        datetime: '日時',
        deactivated: '退会済み',
        defaultTeamCategory: 'デフォルトのチームとカテゴリー',
        delete: '削除',
        deleteCalendarIntegrationFilterSuccess: '条件を削除しました。',
        deleteCustomField: 'カスタムフィールドの削除',
        deleteCustomFieldConfirm: '上記のカスタムフィールドと、カテゴリーに紐付いたカスタムフィールド入力値が全て削除されることを確認しました。',
        deleteCustomFieldDescription: '下記のカスタムフィールドと、カテゴリーに紐付いたカスタムフィールド入力値は全て削除されます。',
        deleteEmptyTask: '作業履歴は0件です。このタスクを削除しますか？',
        deleteFiltering: '条件の削除',
        deleteFilteringDescription: '以下の条件を削除しますが、よろしいでしょうか？',
        deleteForever: '完全に削除',
        deletePayment: '課金を停止',
        deletePaymentDescription1: '課金を停止するとライトプランの機能がご利用いただけなくなります。',
        deletePaymentPermission: '課金の停止はお支払いを行ったユーザーのみが可能です。',
        deleteReminderDescription: 'アラート設定を削除します。よろしいですか？',
        deleteReminderEmailsSetting: 'アラートの削除',
        deleteReportBookmarkDescription: '以下の条件を削除してよろしいですか？',
        deleteReportBookmarkHeader: '条件を削除する',
        deleteReportBookmarkNotice: '※ 選択中の条件のデータが消えることはありません',
        deleteWorkspace: 'ワークスペースの削除',
        deleteWorkspaceConfirm: '上記ワークスペース、チームとそれに属するカテゴリー、タスク、作業履歴が全て削除されることを確認しました。',
        deleteWorkspaceDescription: 'ワークスペースに作成されたチームと、チームに記録された全てのデータが削除されます。',
        details: '詳細',
        disabledEmployNotice: '同一期間のチーム内単価とワークスペース単価は、チーム内単価が優先されます。',
        disconnect: '解除',
        disconnectNote: '外部カレンダーとの連携を解除します',
        disconnectTitle: '外部カレンダー連携の解除',
        disconnectedIdentity: 'アカウントの認証解除に成功しました。',
        doCreateNew: '新しく%{target}を作る',
        done: '完了',
        dontShowAgain: '次回から表示しない',
        dontShowThisMessage: 'このメッセージを表示しない',
        download: 'ダウンロード',
        dragAndDropFile: 'ファイルをドラッグ＆ドロップ',
        duration: '時間',
        edit: '編集',
        editNamedTask: 'タスク「%{name}」を編集',
        editTaskConfirmModalDescription1: 'タスクを変更します。',
        editTaskConfirmModalDescription2: 'この変更により、このタスクに紐づく以下のユーザーの作業履歴も変更されます。',
        editTaskConfirmModalTitle: 'タスクの変更',
        editTeam: 'チーム編集',
        email: 'メールアドレス',
        emailConfirmationCanceled: 'メールアドレスの変更をキャンセルしました。',
        emailConfirmationResent: 'ご本人様確認メールを再送信しました。メールをご確認ください。',
        emailConfirmationSent: 'ご本人様確認メールを送信しました。メールをご確認ください。',
        employByTeams: '各チームでの単価',
        employSettingsForTeam: 'チーム単価設定',
        employSettingsForWorkspace: 'ワークスペース単価設定',
        enterComments: 'コメントを入力',
        export: 'エクスポート',
        exportCSVFile: 'CSVファイルをエクスポート',
        exportCategoriesAndTasks: 'カテゴリー・タスクをエクスポート',
        exportComplete: 'エクスポート完了',
        exportFailed: 'エクスポート失敗',
        exportFromTeam: '以下のチームのデータをエクスポートします',
        exporting: 'エクスポート中',
        exportingNow: '現在エクスポート中です。',
        failed_invalid_data: 'データエラー',
        failed_lines_limit: '行数制限エラー',
        failed_malformed: 'CSV形式エラー',
        failed_unexpected: '予期せぬエラー',
        failure: '失敗',
        favorite: 'お気に入り',
        filename: 'ファイル名',
        filter: '絞り込み',
        filterAndSelect: '絞り込んで選択',
        filtering: 'チームとカテゴリーの自動振り分け',
        filteringNote: '予定のタイトルにキーワードが含まれていた場合、指定したチームのカテゴリーの打刻として保存します',
        firstFetchIsNotFinished: '外部カレンダーからデータの取り込み中です。完了するまでお待ちください',
        fiscalYearly: '年度',
        followingCategoriesDuplicated: '以下カテゴリーの入力値が重複しています。',
        fromDate: '{{date}} から',
        fromWorkspace: 'ワークスペースより',
        fromWorkspaceDescription: 'ワークスペースオーナーにより設定',
        generalSettings: '基本設定',
        generateLatestReport: '最新のレポートを作成',
        getInvitationURL: '%{target}をお持ちの方に招待URLを発行してもらい、その%{target}に参加しましょう。',
        getTitleAndURLfromPage: '閲覧中のページからタイトルとURLを取得',
        goToCalendarIntegration: '外部カレンダー同期を設定する',
        goToCategorySettings: 'カテゴリー設定画面へ',
        goToLegacyReport: '旧レポートへ',
        goToSettings: '設定画面へ',
        goToTeamReport: 'このチームのレポートを見る',
        hasOverlapTimeEntry: '%{count}件の重複があります',
        help: 'ヘルプ',
        here: 'こちら',
        hours: '時間',
        hours_short: '時間',
        icon: 'アイコン',
        ignoreCalendarNote: '予定のタイトルにキーワードが含まれていた場合、TimeCrowd上の予定に表示しません',
        ignoreMinutes: '予定時間による除外',
        ignoreMinutesLabel: '一定時間以上の予定を表示させない',
        ignoreMinutesNote: '分以上で表示させない',
        ignoreWords: 'キーワードによる除外',
        import: 'インポート',
        importCSVFile: 'CSVファイルをインポート',
        importComplete: 'インポート完了',
        importFailed: 'インポート失敗',
        importIntoTeam: '以下のチームに反映されます',
        importingCSVFile: 'CSVファイルをインポート中です',
        inTeam: 'チーム内',
        inactive: '稼働なし',
        includeArchived: 'アーカイブ済みを含める',
        includeHolidays: '祝日を含める',
        inputNextStartTime: '直後の開始時刻を入力',
        inputPrevStopTime: '直前の終了時刻を入力',
        integrateWithGoogleCalendar: 'Googleカレンダーと連携',
        integrateWithMyTask: 'マイタスクのカレンダーと外部カレンダーを同期します',
        integrateWithOutlookCalendar: 'Outlookカレンダーと連携',
        invalidCapacity: '上限設定が正しくありません',
        invitationDisallowed: '招待権限がありません',
        invitationDisallowedDescription: 'ワークスペースオーナー(管理者)はチームオーナーのあなたに新規メンバー招待を許可するよう、設定が可能です。設定方法を以下より紹介しています。',
        invitationLinkTo: '%{name}への招待リンク',
        invitationUrlFor: '%{name}招待URL',
        invite: '招待',
        inviteMembers: 'メンバー招待',
        inviteMembersToTeam: 'チームにメンバーを招待してください',
        inviteNewMember: '新規メンバーを招待',
        inviteToTeam: 'チームに招待',
        joinExisting: 'ほかの人の%{target}に参加したい場合',
        joinTo: '%{target}に参加しましょう',
        keywords: 'キーワード',
        lastFiscalYear: '昨年度',
        lastMonth: '先月',
        lastQuarter: '前四半期',
        lastUpdated: '最終更新',
        lastWeek: '先週',
        leaveComment: 'コメントを入力',
        leaveParentTeam: '子チームから外す',
        leaveWorkspace: 'ワークスペースから外す',
        leaved: '脱退済み',
        linkedAccount: 'アカウント',
        linkedCalendar: '同期するカレンダー',
        list: 'リスト',
        loggedInAsSupportAccount: '社内アカウントでログイン中',
        loginAccount: 'ログインアカウント',
        longTermReport: '長期レポート',
        longTermReportCacheDescription: 'ほとんど待ち時間なく表示可能です。データは後から最新化できます。',
        longTermReportCacheLabelMain: '速度重視',
        longTermReportCacheLabelSub: '前回のデータで作成',
        longTermReportCacheTitle: '前回取得%{date}時点でのデータが見つかりました',
        longTermReportDescription1: '長期レポートでは長期間の月次、四半期、年度のレポートを作成できます。',
        longTermReportDescription2: '初めてデータ作成する期間および期間中のデータ量が多い場合、数分から数十分作成にお時間をいただきます。',
        longTermReportGenerateDescription: '数分から数十分お時間をいただきますが、最新のデータでレポートを作成します。<br>その間、別の画面を閲覧してもデータの作成は進行します。完了しましたら画面上でお伝えします。 ',
        longTermReportGenerateLabelMain: '最新化重視',
        longTermReportGenerateLabelSub: '最新のデータで作成',
        longTermReportGenerateNotice: '次回以降は同じ期間のデータを再利用でき、表示速度を早めることができます。',
        longTermReportGenerateTitle: '初めて取得する期間のため、最新のデータで作成します',
        longTermReportGenerated: '長期レポートの作成が完了しました！',
        longTermReportGeneratingCancel: '長期レポートの作成をキャンセル',
        longTermReportGeneratingDescription: '新しい期間の長期レポートを作成したい場合はキャンセルしてください。',
        longTermReportGeneratingTitle: '現在、長期レポート作成中です',
        longTermReportLoadingDescription: '別の画面を見ている間も長期レポート作成は継続されます',
        longTermReportLoadingStatus: {
            pending: '準備中…',
            processing: 'レポート作成中…',
        },
        longTermReportLoadingTitle: '長期レポート作成中',
        longTermReportShow: '長期レポートを表示',
        makeNotWorkspaceOwner: '%{name}さんのワークスペースオーナー権限を解除しました',
        makeWorkspaceOwner: '%{name}さんをワークスペースオーナーにしました',
        manageChildTeams: '子チーム管理',
        manageMembers: 'メンバーの追加・削除',
        manageTeams: 'チームを管理',
        maxTeamCountNotice: {
            link: 'お問い合わせ',
            prefix: 'さらにチームを追加したい場合は',
            suffix: 'ください。',
        },
        member: 'メンバー',
        memberListSearchPlaceholder: 'メンバー名',
        memberManagement: 'メンバー管理',
        memberSearchPlaceholder: {
            realtime: 'メンバー、チーム、カテゴリー、タスク名',
            schedule: 'メンバー、チーム名',
        },
        minutes: '分',
        minutes_short: '分',
        month: '%{month}月',
        monthBeforeLast: '先々月',
        monthly: '月次',
        moveToPersonalSettings: '個人設定へ移動',
        multiwordsNote: '改行区切りで複数可',
        mustBeUniqueKeywords: '%{keywords}は%{count}件の既存条件のキーワードと重複しています。別のキーワードを設定してください。',
        myReport: 'マイレポート',
        myReportNotice: 'マイレポートではあなた自身の記録を確認できます',
        nEntries: '%{num}件',
        nMembers: '%{num}人所属中',
        nPeople: '%{num}人',
        nTeams: '%{num}チーム',
        name: '名前',
        newCustomField: '新規カスタムフィールド',
        newEmail: '新しいメールアドレス',
        newTeam: '新規チーム作成',
        next: '次へ',
        nextDay: '明日',
        noCustomFieldFound: 'まだ追加されていません',
        noHistoryFound: '履歴がありません',
        noMoreTasksForCategory: 'このカテゴリーのタスクは以上です',
        noReportBookmarkFound: '保存済みの条件はまだありません',
        noSetting: '設定なし',
        noTasksForCategory: 'このカテゴリーのタスクはありません',
        noTasksForKeyword: '入力したキーワードにマッチするタスクはありませんでした',
        noTeam: '無所属',
        noTeamFound: 'チームがありません',
        notFound: {
            back: '戻る',
            description: 'あなたがアクセスしようとしたページは移動、または削除された可能性があります。もしくはご指定のURLが間違っているかもしれません。',
            title: 'お探しのページは見つかりませんでした。',
        },
        notSelected: '未選択',
        notWorking: '未稼働',
        notesForCharges: '※ 決済が実行された翌日に表示されるようになります。',
        notesForUnconfirmedEmailHtml: '%{email} にご本人様確認メールを送信しました。<br>メールをご確認ください。',
        notifyWhenComplete: '処理が完了したら %{email} にお知らせいたします',
        now: '現在',
        numberOfTeams: 'チーム数',
        openTimeCrowdOnBrowser: 'ブラウザー版のTimeCrowdを開く',
        operationRestrictedByAdministrator: '管理者の設定により制限されているためご利用になれません',
        or: 'または',
        others: 'その他',
        othersShort: '他',
        otp: 'ワンタイムパスワード',
        outlookCalendar: 'Outlookカレンダー',
        paidUser: '支払いユーザー',
        paymentDeleted: '課金が正常に停止されました。',
        paymentIsFailed: 'ご登録のクレジットカードでの決済に失敗しました。カード情報を更新するか、他のカードをご登録ください。',
        personal: 'パーソナル',
        personalSettings: '個人設定',
        personalUse: '個人利用の方はこちら',
        phoneNumber: '電話番号',
        plan: 'プラン',
        pleaseChangeAccountWithBrowser: 'アカウントの変更はブラウザー版のTimeCrowdからのみ行なうことが可能です。',
        pleaseConfigureCategory: 'カレンダー連携設定に使用していたカテゴリーが削除またはアーカイブされました。設定しなおしてください。',
        pleaseConfigureWorkspace: 'カレンダー連携設定に使用していたワークスペース設定が解除されました。設定しなおしてください。',
        pleaseConfigureWorkspacesCalendarIntegrationFiltersCategory: 'ワークスペースのカレンダー連携設定に使用していたカテゴリーが削除またはアーカイブされました。設定しなおしてください。',
        pleaseIntegrateWithBrowser: '外部カレンダーの連携はブラウザー版のTimeCrowdからのみ行なうことが可能です。',
        pleaseReloadPage: '新しいバージョンがリリースされている可能性があります。ページを再読み込みしてください。',
        pleaseSelect: '選択してください',
        pleaseSelectCalendarIntegrationSettingWorkspace: '振り分け設定を適用するワークスペースを選択してください',
        pleaseSelectCategory: 'カテゴリーを選択',
        pleaseSelectCategoryOfCalendarIntegrationFilterHtml: '%{label}にて、%{count}件のカテゴリー未選択状態の条件があります。<br>条件にカテゴリーを設定または、不要な条件であれば削除してください。',
        pleaseSelectList: '同期するカレンダーを選択してください',
        pleaseSelectOneOwner: '１名以上オーナー権限のユーザーを追加してください',
        preaseSelectTeam: 'チームを選択してください',
        priority: '優先順位',
        processingResult: '処理結果',
        profile: 'プロフィール',
        quarterly: '四半期',
        rate: '単価',
        rateHelpForTeam: 'このチームでの打刻時の単価を設定できます。ここでチームごとに設定した単価は、ワークスペースでの単価設定があった場合でも優先されます。',
        rateHelpForWorkspace: '設定した単価はユーザーのワークスペース内全てのチームの打刻に使用されます。<br>ただしユーザー所属の各チームごとに別の単価を設定したい場合、各チームでの単価設定から設定できます。',
        readMore: 'さらに読み込む',
        realtimeReport: 'リアルタイムレポート',
        realtimeReportDescription: 'リアルタイムレポートでは3ヶ月分までの期間を常に最新のデータで閲覧可能です。',
        recent12Months: '直近12ヶ月',
        recent2FiscalYears: '2年間',
        recent2Months: '直近2ヶ月',
        recent2Weeks: '直近2週間',
        recent6Months: '直近6ヶ月',
        recentCategories: '最近使用したカテゴリー',
        recentCategoriesProxy: '%{nickname}さんが最近使用したカテゴリー',
        recentTasks: '最近作業したタスク',
        recentTasksProxy: '%{nickname}さんが最近作業したタスク',
        recommended: '推奨',
        refreshing: '更新中',
        refunded: '返金済み',
        relatedTasks: '他カテゴリーの関連タスク',
        reload: '再読み込み',
        reminderCondition: '条件',
        reminderConditions: {
            none: '条件なし',
            not_working: '打刻していなかったら',
            working: '打刻していたら',
        },
        reminderDay: '曜日',
        reminderDays: {
            /* eslint-disable sort-keys */
            monday: '月',
            tuesday: '火',
            wednesday: '水',
            thursday: '木',
            friday: '金',
            saturday: '土',
            sunday: '日',
            /* eslint-enable sort-keys */
        },
        reminderEmails: 'アラート設定',
        reminderEmailsDescription1: '各メンバーの登録メールアドレス宛にアラートメッセージが送信されます。',
        reminderEmailsDescription2: 'アラートの種類',
        reminderEmailsDescription3: 'メッセージ: 設定時刻にメッセージを送信します。打刻の有無で条件付けすることも可能です。',
        reminderEmailsDescription4: '止め忘れアラート: 設定時間以上経過しているタスクがあった場合にアラートメッセージを送信します。',
        reminderEmailsDescription5: '打刻漏れアラート: 前営業日の打刻時間が設定時間に満たない場合にアラートメッセージを送信します。',
        reminderIgnore: '送信休止日',
        reminderMessage: 'メッセージ',
        reminderMessages: {
            forgot_record: '前営業日の打刻合計時間が{h}時間{mm}分を満たしていません。<br>打刻漏れの可能性があります。',
            forgot_stop: '{h}時間{mm}分以上打刻しているタスクがあるようです。<br>打刻の止め忘れの可能性があります。',
        },
        reminderMinDuration: '最低時間',
        reminderReceiver: 'アラート対象',
        reminderReceivers: {
            all: '全員',
            member: 'メンバー',
            owner: 'オーナー',
        },
        reminderSendTime: '送信日時',
        reminderTime: '時刻',
        reminderType: '種類',
        reminderTypes: {
            forgot_record: '打刻漏れアラート',
            forgot_stop: '止め忘れアラート',
            general: 'メッセージ',
        },
        reminderWorkingDuration: '経過時間',
        remove: '削除',
        removed: '削除済み',
        renewInvitationUrl: '招待URLの再発行',
        renewInvitationUrlDescription1: '招待URLの再発行後は、古い招待URLは使用できなくなりますのでご注意ください。',
        renewInvitationUrlDescription2: '招待URLを再発行は、基本的に招待URLが有効期限切れの時、また不本意な相手に招待URLを知られてしまった時に行うと有効です。',
        renewUrl: 'URLを再発行',
        reportBookmarkCreated: '条件を保存しました',
        reportBookmarkDeleted: '条件を削除しました',
        reportBookmarkFavorited: 'お気に入り条件を追加しました！',
        reportBookmarkReachedLimit: '条件を保存できる上限に達しました。保存できるのは50件までです。',
        reportBookmarkUnfavorited: 'お気に入り条件から解除しました',
        reportBookmarkUpdated: '条件を更新しました',
        reportBookmarks: '保存済み条件',
        reportMail: 'レポートメール',
        reportRecipientsSettings: 'レポートメールを他のメールアドレスにも送信したい場合は、以下のリンク先にある「送信先」に追加してください。',
        resend: '再送信',
        reset: 'リセット',
        restore: '元に戻す',
        roles: {
            manager: 'チームオーナー',
            member: 'メンバー',
            owner: 'ワークスペースオーナー',
        },
        rounding: '稼働金額の端数',
        roundings: {
            ceil: '切り上げ',
            floor: '切り捨て',
            round: '四捨五入',
        },
        save: '保存',
        saveThisCondition: 'この条件を保存',
        saved: '保存済み',
        saving: '保存中...',
        search: '検索',
        searchByCategoryName: 'カテゴリー名で検索',
        searchByCategoryOrTeamName: 'カテゴリー名やチーム名で検索',
        searchByTeamName: 'チーム名検索',
        searchByUserName: 'ユーザー名検索',
        searchResult: '検索結果',
        seconds: '秒',
        seeDetailedFeatureDescription: '詳しい機能の説明を見る',
        selectAccountAndIntegrateWithOutlook: '以下のボタンからGoogleカレンダーまたはOutlookカレンダーを指定して設定開始してください',
        selectAll: '全選択',
        selectCalendarIntegrationSettingBaseType: '適用する同期設定',
        selectFile: 'ファイルを選択',
        selectFileSuffix: 'してください',
        selectLeafs: '全ての最下層カテゴリーを選択',
        selectParents: '全ての親カテゴリーを選択',
        selectPeriod: '期間を選ぶ',
        selectTeam: 'チームを選択',
        selected: '選択済み',
        selectingNUsers: '%{count}名のユーザーを選択中',
        sendConfirmation: '本人確認メールを送信',
        setAutoStopTimeTracking: '設定する',
        setNameFor: '%{target}名を設定してください',
        settings: '設定',
        shareURL: '以下のURLをチームに招待したいメンバーにお知らせください。',
        showAllUsersReport: 'チーム全員の記録を見る',
        showBelow: '以下を表示',
        showCategoryOnWeeklyCalendar: 'タスクのカテゴリーを表示',
        showCategoryOnWeeklyCalendarDescription: '週次カレンダーでタスクのカテゴリーを表示します',
        showLess: '少なく表示',
        showMore: 'さらに表示',
        showMoreItems: '残りの%{count}件を表示する',
        showRecentTasks: '最近作業したタスクを見る',
        signInWithGoogle: 'Googleアカウントでログイン',
        signInWithMicrosoft: 'Microsoftアカウントでログイン',
        startGeneration: 'データ作成開始',
        startMode: {
            auto: 'カレンダーの予定を開始しない（打刻中のタスクを維持する）',
            force: 'カレンダーの予定を開始する（打刻中のタスクを停止する）',
        },
        startModeLabel: '予定開始時刻に打刻中の場合',
        startMonth: '年度開始月',
        startMonthHelpForLongTerm: '年度開始月によって、レポートの集計開始月を変更できます。',
        startMonthHelpForRealtime: '年度開始月によって、四半期の区切り方を変更できます。',
        started_at: '開始日時',
        stop: '停止',
        stopMemberEntryModal: {
            description: '%{user}の作業中のタスクを停止しますか？',
            stop: '停止する',
            title: 'メンバーの作業中のタスクを停止',
        },
        stopMode: {
            auto: '予定の終了時刻で停止させる（自動で停止）',
            none: '予定の終了時刻で停止させない（手動で停止）',
        },
        stopModeLabel: '予定終了時刻',
        stopped_at: '停止日時',
        stopping: '停止中',
        subscribePaidPlan: '有料プランに申し込む',
        summary: '概要',
        targetCreated: '「%{target}」を作成しました',
        targetDeleted: '「%{target}」を削除しました',
        targetUpdated: '「%{target}」を更新しました',
        task: 'タスク',
        taskCreated: 'タスク「%{name}」を作成しました',
        taskHistory: '作業履歴',
        taskInfo: 'タスク情報',
        taskSettings: 'タスク編集',
        tasksBulkCreated: 'タスクは正常に作成されました',
        team: 'チーム',
        teamCategory: 'チームとカテゴリー',
        teamEmploysDescription: 'このメンバーに対して、各チームで設定した単価を表示しています。チームごとに設定した単価は、ワークスペースでの単価設定より優先されます。',
        teamEmploysTitle: 'チーム内単価設定',
        teamManagement: 'チーム管理',
        teamMemberManagement: 'チームメンバー管理',
        teamName: 'チーム名',
        teamNameExampleForPersonal: '例：個人用 / ◯◯プロジェクト',
        teamOwnersInviteAllowedDescription: 'あなた方ワークスペースオーナーは、各チーム設定でワークスペースに新しいメンバーを招待できる権限を持っています。<br>この権限をチームオーナーにも与えられます。',
        teamOwnersInviteAllowedLabel: '各チームのオーナーがワークスペースに新しいメンバーを招待するのを許可する',
        teamOwnersInviteAllowedTitle: 'チームオーナーの権限',
        teamRole: 'チーム権限',
        teamSettings: 'チーム設定',
        teams: 'チーム',
        teamsAndCategories: 'チームとカテゴリー',
        thankYouRegister: 'ご登録ありがとうございます',
        thereAreBlankRequiredFields: '入力必須項目が入力されていません',
        thisFiscalYear: '今年度',
        thisIsForPaidTeams: 'こちらの機能は有料チームのみの機能となっております',
        thisMonth: '今月',
        thisWeek: '今週',
        timeEntry: '作業履歴',
        timeEntryInProgress: '作業中',
        timeZoneAndLocale: 'タイムゾーンと言語',
        time_entry: '作業履歴',
        title: 'タイトル',
        toDate: '{{date}} まで',
        today: '今日',
        totalAmount: '合計金額',
        totalDuration: '稼働合計',
        totalWorkingTime: '合計作業時間',
        trialEndsDate: 'トライアル終了日',
        trialRemainingDays: '（残り%{days}日）',
        twoFactorAuth: '二要素認証',
        unarchive: 'アーカイブ解除',
        unconfirmedRecipient: '送信先メールアドレスが未確認です。確認メールのリンクをクリックしてください。',
        undo: '元に戻す',
        unitMinutes: '作業履歴入力単位',
        unitMinutesDescription: 'カレンダー上で作業履歴を入力するときの単位を選択できます。',
        unitPrice: '単価',
        unpaid: '未課金',
        unpaidAlerts: {
            reminderEmails: "<strong className='mr-1'>%{name}</strong>テストチームは無料プランに変更されたため、アラート機能を制限しました。<br>引き続きアラート機能を利用するには有料プランへの移行が必要です。",
        },
        unrecoverableError: '予期せぬエラーが発生したため、上記アカウントとの連携が解除されました。<br>お手数をおかけしますがアカウントの連携をやりなおしてください。',
        unset: '未設定',
        update: '更新',
        updateCalendarIntegrationFilterSuccess: '条件を更新しました。',
        updateCalendarIntegrationSettingSuccess: 'カレンダー同期の設定を更新しました。',
        updateReportBookmarkHeader: '保存済み条件詳細',
        updateReportBookmarkTitleLabel: '条件名を編集',
        url: 'URL',
        user: 'ユーザー',
        userId: 'ユーザーID',
        username: 'ユーザー名',
        users: 'ユーザー',
        walkthroughPlanButtonDescription: 'すべての機能をご利用いただけます',
        walkthroughPlanButtonLabel: '無料トライアル',
        walkthroughPlanLinkLabel1: 'すべての機能についてお問い合わせ',
        walkthroughPlanTitle: 'ご利用を始めたい法人組織の方',
        walkthroughWelcomeNotice1: '招待URLからアクセスした方',
        walkthroughWelcomeNotice2: '招待を受けた場合、こちらからTimeCrowdを始めず、もう一度招待URLからTimeCrowdに参加してください。',
        weekOfMonthFormat: 'MMM / %{week}週',
        weekly: '週次',
        willDeleteTeams: '削除されるチーム',
        workspace: 'ワークスペース',
        workspaceCalendarIntegrationSettingDescription1: 'ワークスペース所属のメンバー全員が、共通のカレンダー同期設定を利用できます。',
        workspaceCalendarIntegrationSettingDescription2: 'ただし利用するメンバーは、メンバー自身の個人設定でのカレンダー連携設定が必要です。あなた自身のカレンダー設定はこちらから',
        workspaceDefaultBlankValue: 'ワークスペースを選択してください',
        workspaceId: 'ワークスペースID',
        workspaceInvitationDescription1: 'チームへの所属が決まっていないメンバーを招待する時、こちらのURLをご利用ください',
        workspaceInvitationDescription2: 'このURLは複数人の招待に使うことができます。',
        workspaceInvitationDescription3: 'メンバー以外の人にこのURLが知られないようにご注意ください。',
        workspaceInvitationDescription4: '所属チームが決まっているメンバーの招待はこちらから',
        workspaceInvitationUrl: 'ワークスペース招待URL',
        workspaceName: 'ワークスペース名',
        workspacePersonalNote1: 'パーソナルプランで作成できるチームは1つのみです。',
        workspacePersonalNote2: '複数チームの作成や自分以外のメンバーの追加をご要望の場合は',
        workspacePersonalNote3: 'ください。',
        workspacePlanNotes: {
            general: '利用人数の増減によるプランの変更は営業担当にご連絡ください。',
            personal: 'チームでの利用をご検討のお客様は機能のご説明やトライアルのご案内が可能です。<br>お気軽にお問い合わせください。',
            trial: 'ご契約やトライアル期間の延長のご相談など随時承っております。<br>営業担当へお気軽にご連絡ください。',
        },
        workspacePlans: {
            basic: 'ベーシック',
            business: 'ビジネス',
            enterprise_trial: '法人プラン - トライアル',
            legacy_enterprise: 'エンタープライズ',
            legacy_light: 'ライト',
            legacy_standard: 'スタンダード',
            minimum: 'ミニマム',
            personal: 'パーソナル',
            starter: 'スターター',
        },
        workspaceRole: 'ワークスペース権限',
        yesterday: '昨日',
        you: 'あなた',
        yourTime: 'あなたの時間',
    },
    taskEdit: {
        addTimeEntry: '新しい作業履歴',
        editTask: 'タスクの編集',
        taskName: 'タスク名',
    },
    taskManagement: {
        all: 'すべて',
        allTeams: 'すべてのチーム',
        allUsers: 'すべてのユーザー',
        blankDescription: 'タスクを追加してみましょう',
        blankDescriptionClosed: 'タスクを完了させましょう',
        blankTitle: 'タスクがありません',
        blankTitleClosed: '完了したタスクはありません',
        blankTitleOpen: '未完了のタスクはありません',
        blankTitleTrashed: '削除済みのタスクはありません',
        closed: '完了',
        deleteTasksAfterTwoWeeks: '「削除済み」のタスクは、2週間後に自動的に削除されます。',
        open: '未完了',
        taskTitle: 'タスクのタイトル',
        trashed: '削除済み',
    },
    taskMergeModal: {
        attention: '全作業履歴を同じカテゴリー内の同じ名前のタスクに移動します。自分以外の作業履歴も移動になりますので、ご注意ください。',
        desc: '同じカテゴリー内に同じ名前のタスクが存在します。変更先のタスクに作業履歴を移動しますが、よろしいですか？',
        submit: '移動',
        title: 'タスクの統合',
    },
    tasklist: {
        all: 'すべて',
        category: 'カテゴリー',
        delete: 'このタスクを削除',
        done: '完了',
        edit: '編集',
        history: '履歴',
        link: 'タスクのURLへ移動',
        pin: 'ピン',
        start: '開始',
        status: 'ステータス',
        stop: '停止',
        view: '表示',
        yet: '未完了',
    },
    teamChildren: {
        attention: '「%{childTeamName}」を「%{parentTeamName}」の子チームから外しますがよろしいですか？',
        notExistChildren: '子チームが存在しません',
    },
    teamManagement: {
        addEmploy: '単価を設定する',
        addScheduledEmploy: '単価の変更予定を設定する',
        addTeam: '新しいチームを作成',
        addvancedSettings: '高度な設定',
        avatar: 'アバター',
        billingForm: {
            accept: 'プラン変更を受け付けました',
            amount: '金額',
            annotationForInvoicePayment: {
                afterText: 'ください。',
                beforeText: '法人プランや請求書払いをご希望の方はクレジットカード情報を入力せずに',
                helpText: 'お問い合わせ',
            },
            annotationForPlanApplication: '※こちらはライトプランのお申し込みになります。',
            apply: '申し込む',
            change: '有料プランへの変更',
            complete: '申し込みを完了する',
            date: '日付',
            free: '無料',
            noNeed: 'このチームはプレミアム設定がされているので課金は不要です。',
            paid: '支払い済み',
            paymentInfo: '課金情報',
            receipt: '領収書発行',
            report: '課金することでレポートの閲覧ができるようになります。',
            status: '状態',
            thankYou: 'お申込みありがとうございます。',
            title: 'プラン',
            unpaid: '未払い',
        },
        category: 'カテゴリー',
        categoryMenu: {
            archiveCategory: 'このカテゴリーをアーカイブする',
            copyCategory: 'このカテゴリーをコピーする',
            createChildCategory: 'このカテゴリーの子カテゴリーを作成する',
            moveHigherCategoryPosition: 'このカテゴリーの順番を上げる',
            moveLowerCategoryPosition: 'このカテゴリーの順番を下げる',
            unarchiveCategory: 'このカテゴリーをアーカイブから戻す',
        },
        categoryPage: {
            add: '新規カテゴリー',
            categoryTitle: 'タイトル',
            info: 'カテゴリー情報',
            member: 'メンバー',
            noticeForCopy: 'カテゴリー「%{category}」の子カテゴリーやタスクをコピーして、新しいカテゴリーを作成します。 <br>※ 作業履歴はコピーされません',
            parentCategory: '親カテゴリー',
            selectColor: 'カラー',
            title: 'カテゴリー設定',
            toBeCopied: 'コピーされるカテゴリー・タスク',
        },
        deleteMembership: '外す',
        destroyTeam: {
            attention: '以下のチームとそれに属するカテゴリー、タスク、作業履歴は全て削除されます。（アーカイブ済みのカテゴリーも含まれます）',
            attention_children: '以下の子チームも削除されます。',
            attention_payment_page: 'ヘルプページ',
            attention_payment_prefix: '有料プランの停止方法は',
            attention_payment_suffix: 'をご確認ください。',
            confirmation: '上記チームとそれに属するカテゴリー、タスク、作業履歴が全て削除されることを確認しました。',
            delete: 'チーム削除',
            noticePaidTeam1: '%{name}は現在有料プランを継続中です。',
            noticePaidTeam2: '%{target}を削除するにはお手数ですが有料プランの停止をお願いします。有料プランを停止後、%{target}を削除することができます。',
            noticeParent: '%{name}は子チームがあるため削除できません。',
            noticePersonal: '%{name}はパーソナルチームのため削除できません。',
        },
        editTeam: {
            title: 'チームを編集',
        },
        employ: '単価設定',
        integration: '外部サービス',
        invitation: {
            byUrl: {
                attention: 'この招待URLを受け取った人はこの%{target}のタスクを閲覧したりすることができるようになります。メンバー以外の人にこのURLが知られないようにご注意ください。',
                expiresAt: '有効期限',
                pleaseRenew: '招待URLの有効期限が切れています。URLを再発行してください。',
                renew: 'URLを再発行',
                renewed: 'URLを再発行しました',
                title: '招待URL',
            },
        },
        legacy: '旧バージョンの設定',
        member: 'メンバー',
        memberTab: {
            editPermissions: '権限を編集',
            rate: '単価',
            title: 'メンバー管理',
        },
        membershipRoleInput: {
            updateSuccess: '{{nickname}}さんの権限が{{role}}になりました',
        },
        newTeam: {
            defaultName: '新しいチーム',
        },
        payByCreditCard: 'クレジットカードで支払う',
        payWithInvoice: '請求書で支払う',
        paymentHint1: '2週間無料でお試しいただけます。',
        paymentHint2: '2週間を過ぎると一部の機能が使用できなくなります。',
        paymentHint3: '継続して全ての機能を利用するには課金処理をしていただく必要があります。',
        paymentHintLabel: 'ご利用にあたって',
        paymentManualHint: '課金方法については下記マニュアルページをご参照ください。',
        permissionTab: {
            add: '新規権限',
        },
        personalTeamAlert: {
            afterText: 'をご覧ください。',
            beforeText: 'こちらは個人でお使いいただくチームのため、メンバーを招待することはできません。複数人でご利用の場合は、チームを作成してください。<br />※チームの作成手順に関しては',
            helpText: 'こちら',
        },
        plan: 'プラン',
        removeCategoryModal: {
            attention: '下記のカテゴリー、また当該カテゴリーに属するタスク・作業履歴は全て削除されます。（アーカイブ済みのカテゴリーも含まれます）',
            confirmation: '上記のカテゴリーと当該カテゴリーに属するタスク・作業履歴が全て削除されることを確認しました。',
            title: 'カテゴリーの削除',
        },
        removeFromTeam: 'チームから外す',
        removeUserFromTarget: '%{target}からメンバーを外す',
        removeUserFromTargetConfirm: '以下のユーザーを%{target}のメンバーから外してよろしいですか？',
        roundingHint1: '金額は各作業ログごとに、次の計算式で算出されます。',
        roundingHint2: '時給 × 作業秒数 ÷ 3600',
        roundingHint3: '小数点以下の端数はこの設定にしたがって処理されます。',
        settings: 'チーム設定',
        teamId: 'チームID',
        teamName: 'チーム名',
        timeLimit: 'タイムリミット（分）',
        timeLimitHint1: '連続して作業できる時間の上限です。',
        timeLimitHint2: '0を指定すると上限無しになります。',
        webHook: 'Webフック',
    },
    timeEntryMergeModal: {
        attention: '全作業履歴を変更先カテゴリーの同じ名前のタスクに移動します。自分以外の作業履歴も移動になりますので、ご注意ください。',
        desc: '変更先カテゴリーに同じ名前のタスクが存在します。変更先のタスクに作業履歴を移動しますが、よろしいですか？',
        submit: '移動',
        title: 'タスクのカテゴリーの変更',
    },
    timeInput: {
        error: {
            format: '時間は半角数字でhh:mm形式で入力してください。',
            inclusion: '時間は00:00~23:59の範囲で入力してください。',
        },
    },
    timespan: {
        hasOverwrapTimeEntry: '以下の作業履歴と重複しています。',
    },
    webHook: {
        create: {
            title: 'Webフックを作成',
        },
        eventEntryCreate: '作業履歴追加',
        eventEntryDelete: '作業履歴削除',
        eventEntryEdit: '作業履歴修正',
        eventEntryStart: '作業開始',
        eventEntryStop: '作業停止',
        events: 'イベント',
        listTitle: 'Webフック一覧',
        secret: 'シークレット',
        secretHelp: 'リクエストがTimeCrowdから送信されたものかどうか検証するために使用します。シークレットを秘密鍵としてリクエストボディのHMAC-SHA256ダイジェスト値を取得します。ダイジェストのBase64とTimeCrowd-Signatureヘッダーが一致することを確認してください。',
        url: 'WebフックURL',
    },
    welcome: {
        currentLoginHtml: '<b>%{email}</b>でログイン中',
        logoutOrDeactivate: 'ログアウトや退会したい方',
        logoutOrDeactivateDescriptionHtml: '過去にご利用いただいた方や、<br />間違ったログイン方法で会員登録してしまった方は、<br />以下より操作いただけます。',
    },
    workingusers: {
        edit: '編集',
        start: '開始',
        stop: '停止',
    },
};
