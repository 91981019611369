import React from 'react';
import I18n from '@/constants/i18n';
import Blank from '@/components/molecules/blank';
export var ErrorScreen = function (_a) {
    var onClickLink = _a.onClickLink, status = _a.status, title = _a.title, description = _a.description, onReload = _a.onReload;
    return (<Blank title={title || I18n.t("errors.title.".concat(status))}>
      <p dangerouslySetInnerHTML={{
            __html: description
                ? description
                : I18n.t("errors.description.".concat(status)),
        }}/>
      <p>{I18n.t('errors.contactUs')}</p>
      <div className="mt-4">
        <a className="btn btn-secondary btn-block" href="http://chatwork.com/timecrowd" onClick={onClickLink}>
          {I18n.t('shared.contactViaChatwork')}
        </a>
        <a className="btn btn-secondary btn-block" href="https://timecrowd.net/contacts/new" onClick={onClickLink}>
          {I18n.t('shared.contactViaEmail')}
        </a>
        {status === 500 && (<button className="btn btn-link btn-block mt-3" onClick={onReload}>
            {I18n.t('shared.reload')}
          </button>)}
      </div>
    </Blank>);
};
export default ErrorScreen;
