export var redirectsForChromeExtension = [
    { from: '/html/signed_in.html', to: '/app' },
    { from: '/html/member.html', to: '/app/member' },
    { from: '/html/report.html', to: '/app/report' },
    { from: '/html/other.html', to: '/app/other' },
    {
        from: '/html/personal_settings.html',
        to: '/app/personal_settings/profile',
    },
    { from: '/html/teams.html', to: '/app/teams' },
    { from: '/html/tasks.html', to: '/app/tasks' },
];
