import { __assign } from "tslib";
export var isDev = process.env.NODE_ENV === 'development';
export var isChromeExtension = window.chrome && window.chrome.storage ? true : false;
export var isApp = window.isApp;
export var isMobile = isDev
    ? process.env.NEO_MOBILE === '1'
    : window.isMobile;
export var isDesktop = window.isDesktop;
var target = '';
if (isDev && !isChromeExtension && !isApp && !isDesktop) {
    target = 'dev';
}
else if (isChromeExtension) {
    target = 'crx';
}
else if (isApp) {
    target = 'native';
}
else if (isDesktop) {
    target = 'desktop';
}
else if (isMobile) {
    target = 'web';
}
else {
    target = 'web';
}
var targetVariants = require("./".concat(target))['default']; // eslint-disable-line @typescript-eslint/no-require-imports
export var isPC = !isChromeExtension && !isApp && !isMobile;
export default __assign({ isChromeExtension: isChromeExtension, isApp: isApp, isMobile: isMobile, isPC: isPC, isDesktop: isDesktop }, targetVariants);
