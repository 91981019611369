/* eslint sort-keys: error */
export default {
    apiBase: '',
    appVersion: window.appVersion,
    clearStorage: function () {
        localStorage.clear();
    },
    clearUser: function () { return (location.href = '/sign_out'); },
    getPage: null,
    loadExportCharset: function (callback) { return callback(localStorage.exportCharset); },
    loadExportDataType: function (callback) { return callback(localStorage.exportDataType); },
    loadExportTimeFormat: function (callback) { return callback(localStorage.exportTimeFormat); },
    loadFromPageTitle: function (cb) { return cb(false); },
    loadMyTaskViewMode: function (callback) {
        var viewMode = localStorage.myTaskViewMode
            ? localStorage.myTaskViewMode
            : 'myTask';
        callback(viewMode);
    },
    loadReportHistory: function (namespace, callback) {
        var key = "reportHistory".concat(namespace);
        var history = localStorage[key] ? JSON.parse(localStorage[key]) : null;
        callback(history);
    },
    loadReportSelectedUsers: function (namespace, callback) {
        var key = "reportSelectedUsers".concat(namespace);
        var selectedUsers = localStorage[key]
            ? JSON.parse(localStorage[key])
            : {};
        callback(selectedUsers);
    },
    loadShownPaymentAlertTeamIds: function (callback) {
        var shownPaymentAlertTeamIds = localStorage.shownPaymentAlertTeamIds
            ? JSON.parse(localStorage.shownPaymentAlertTeamIds)
            : [];
        callback(shownPaymentAlertTeamIds);
    },
    loadStorage: function (key, callback) {
        return callback(localStorage[key] ? JSON.parse(localStorage[key]) : null);
    },
    loadTaskManagementHistory: function (namespace, callback) {
        var key = "taskManagementHistory".concat(namespace);
        callback(localStorage[key]);
    },
    loadTeamId: function (callback) { return callback(localStorage.teamId); },
    openURL: function (url) { return window.open(url, '_blank'); },
    reloadPage: function () { return location.reload(); },
    saveExportCharset: function (charset) { return (localStorage.exportCharset = charset); },
    saveExportDataType: function (dataType) { return (localStorage.exportDataType = dataType); },
    saveExportTimeFormat: function (timeFormat) {
        return (localStorage.exportTimeFormat = timeFormat);
    },
    saveFromPageTitle: function () { },
    saveHistory: function () { },
    saveMyTaskViewMode: function (viewMode) { return (localStorage.myTaskViewMode = viewMode); },
    saveReportHistory: function (namespace, selected) {
        var key = "reportHistory".concat(namespace);
        localStorage[key] = JSON.stringify(selected);
    },
    saveReportSelectedUsers: function (namespace, selectedUsers) {
        var key = "reportSelectedUsers".concat(namespace);
        localStorage[key] = JSON.stringify(selectedUsers);
    },
    saveShownPaymentAlertTeamIds: function (value) {
        localStorage.shownPaymentAlertTeamIds = JSON.stringify(value);
    },
    saveStorage: function (key, value) { return (localStorage[key] = JSON.stringify(value)); },
    saveTaskManagementHistory: function (namespace, history) {
        var key = "taskManagementHistory".concat(namespace);
        localStorage[key] = history;
    },
    saveTeamId: function (teamId) { return (localStorage.teamId = teamId); },
    token: null,
    useHashRouter: false,
};
